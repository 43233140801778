import React, {memo, useCallback, useEffect, useMemo, useRef, useState} from "react"

import {Badge, Button, Stack} from "react-bootstrap"

import {getDexImage} from "../../assets/dexes"
import {getAggregatorImage} from "../../assets/aggregators"
import {DashboardFilterData} from "../dashboard"
import VirtualTable, {VirtualTableRefProps} from "../../components/VirtualTable"

import {MODULE_EVENTS, MODULE_EVENTS_PARAMS, WORKER_EVENTS} from "../../common/moduleEvents"
import {DBMiscSettings, DBToken, DBTokens, WorkerIterationResult} from "../../common/types"

import {
    editTokenTradeSlippage,
    toggleTokenNetworkTrackIgnore,
    updateTokenAutomation,
    updateTokenComments,
    updateTokenHidden,
    updateTokenNetworkPairAutomation,
    updateTokenNetworkPairComments,
    updateTokenNetworkPairHidden,
    updateTokenNetworkPairPoopAutomation,
    updateTokenSlippage,
} from "../settings/tokens/actions"
import BigNumber from "bignumber.js"
import {AppState, sendUpdateEvent} from "../../App"
import {
    DBBridges,
    getBridgeNamesByTokenNetworkPairSortedByPriority,
    getTokenNetworkPairPoopAutomation,
    getTokenNetworkTrackIgnore,
    NETWORK,
    NETWORK_EXPLORER_ADDRESS,
    NETWORK_SOURCE_TOKEN,
    REQUESTER,
} from "../../common"
import SocketClient from "../../common_custom/SocketClient"
import {toast} from "react-toastify"

import {eye, eyeSlash, moneyTransfer} from "../../helper/svg"
import {getNetworkImage} from "../../assets/networks"
import {getDexNameAndVersion} from "../../helper/misc"

function getNetworkCellsClassNames(
    resA?: WorkerIterationResult["networkResults"][NETWORK.ETHEREUM],
    resB?: WorkerIterationResult["networkResults"][NETWORK.ETHEREUM]
) {
    if (resA === undefined || resB === undefined) return ["", ""]

    const resAbn = BigNumber(resA.returnAmountShifted)
    const resBbn = BigNumber(resB.returnAmountShifted)

    // if (resAbn.comparedTo(BigNumber(0)) <= 0 || resBbn.comparedTo(BigNumber(0)) <= 0) return ["", ""]

    if (resAbn.comparedTo(resBbn) > 0) return ["biggerPrice", "lowerPrice"]
    if (resAbn.comparedTo(resBbn) < 0) return ["lowerPrice", "biggerPrice"]
    return ["", ""]
}

const columns = [
    {name: "A", key: "automation", minWidth: 40},
    {name: "💩", key: "poop_automation", minWidth: 40},
    {name: eye as any, key: "hide_token", minWidth: 40},
    {name: moneyTransfer as any, key: "autosell", minWidth: 40},
    {name: "Token", key: "token", minWidth: 150},
    {name: "NA", key: "networkA_automation", minWidth: 40},
    {name: "Network", key: "networkA", minWidth: 220},
    {name: "Network", key: "networkB", minWidth: 80},
    {name: "NA", key: "networkB_automation", minWidth: 40},
    {name: "Slippage", key: "slippage", minWidth: 100},
    {name: "Trade_S", key: "trade_slippage", minWidth: 98},
    {name: "Profit", key: "profit", minWidth: 160},
    {
        name: "Comments",
        key: "comments",
        minWidth: 260,
        width: "-webkit-fill-available",
    },
    {name: "F", key: "force_cell", minWidth: 40},
    {name: "P", key: "pin", minWidth: 40},
]

const defillamaNetworks: { [key in NETWORK]?: string } = {
    [NETWORK.ETHEREUM]: "ethereum",
    [NETWORK.BINANCE]: "bsc",
    [NETWORK.ARBITRUM]: "arbitrum",
    [NETWORK.POLYGON]: "polygon",
    [NETWORK.OPTIMISM]: "optimism",
    [NETWORK.BASE]: "base",
    [NETWORK.SNOWTRACE]: "avax",
    [NETWORK.GNOSIS]: "gnosis",
}

/**
 *
 */
type DashboardTableProps = {
    workersRunning: AppState["brainState"]["workersRunning"]
    dashboardDisplayAllPinnedTokens: boolean | undefined
    pinnedTokens: AppState["brainState"]["pinnedTokens"]
    pinnedTokensNoTrade: AppState["brainState"]["pinnedTokensNoTrade"]
    autosellPins: AppState["brainState"]["autosellPins"]
    newHeads: AppState["newHeads"]
    gasEstimate: AppState["gasEstimate"]
    allTokens: DBTokens
    workerTokens: DBTokens
    bridges: DBBridges
    trackingResults: MODULE_EVENTS_PARAMS[WORKER_EVENTS.WORKER_ITERATION_RESULT]
    textFilter: string
    filterData: DashboardFilterData
    miscSettings?: DBMiscSettings
}
export default memo(function DashboardTable({
                                                workersRunning,
                                                dashboardDisplayAllPinnedTokens,
                                                pinnedTokens,
                                                pinnedTokensNoTrade,
                                                autosellPins,
                                                newHeads,
                                                gasEstimate,
                                                allTokens,
                                                workerTokens,
                                                bridges,
                                                trackingResults,
                                                textFilter,
                                                filterData,
                                                miscSettings,
                                            }: DashboardTableProps) {
    const tokensToUse = filterData.displayTokens === "allall" ? allTokens : workerTokens

    const tokenEntries = Object.entries(tokensToUse)
    const virtualTableRef = useRef<VirtualTableRefProps>(null)

    /**
     * Recalculate dynamic table row height on filters change
     *  since filters modify row height and virtualization library can not recalc it if height decreases
     */
    useEffect(() => {
        virtualTableRef.current?.measure()
    }, [filterData])

    /**
     * Returns the BEST estimate for row height
     */
    const estimateRowHeight = useCallback(() => {
        let base = 45
        if (filterData.dexPosition === "bottom") base += 17
        if (filterData.displayRoutes === "true" || filterData.displayRoutes === "bn") base += 17

        return base
    }, [filterData])

    type TokensDataToDisplay = {
        tokenName: string
        tokenData: DBToken
        networkA: NETWORK
        networkB: NETWORK
        trackingResult?: WorkerIterationResult
        req_key: string
    }[]

    const tokensDataToDisplay: TokensDataToDisplay = []

    for (const [tokenName, tokenData] of Object.entries(tokensToUse)) {
        const tokenNetworkNames = Object.keys(tokenData.networkData || {})
        const alphabeticalUniqueNetworkPairs: `${NETWORK}_${NETWORK}`[] = []

        for (const _networkA of tokenNetworkNames) {
            for (const _networkB of tokenNetworkNames) {
                const networkA = _networkA as NETWORK
                const networkB = _networkB as NETWORK

                if (networkA === NETWORK.ATA || networkB === NETWORK.ATA) continue

                if (networkA === networkB) continue

                const networkNamesSorted = [networkA, networkB].sort((a, b) => a.localeCompare(b))

                const alphabeticalNetworkPair =
                    `${networkNamesSorted[0]}_${networkNamesSorted[1]}` as `${NETWORK}_${NETWORK}`

                if (alphabeticalUniqueNetworkPairs.includes(alphabeticalNetworkPair)) continue

                alphabeticalUniqueNetworkPairs.push(alphabeticalNetworkPair)
            }
        }

        // console.log(tokenName, alphabeticalUniqueNetworkPairs)

        let requesters = Object.values(REQUESTER)
        if (miscSettings?.requesters) {
            // Filter enabled
            requesters = requesters.filter((r) => miscSettings.requesters[r])

            // Filter selected
            requesters = requesters.filter((r) => {
                if ((miscSettings.requesterSettings[r]?.tracking ?? "all") === "all") {
                    return true
                }

                return tokenData.selectedRequesters?.[r]
            })
        }

        for (const alphabeticalUniqueNetworkPair of alphabeticalUniqueNetworkPairs) {
            const [networkA, networkB] = alphabeticalUniqueNetworkPair.split("_")
            for (const req_key of requesters) {
                const trackingResult =
                    trackingResults[`${tokenName}_${alphabeticalUniqueNetworkPair}_${req_key}`]

                tokensDataToDisplay.push({
                    tokenName,
                    tokenData,
                    networkA: networkA as NETWORK,
                    networkB: networkB as NETWORK,
                    trackingResult,
                    req_key,
                })
            }
        }
    }

    /**
     * Filtering
     */
    const filteredTokensDataToDisplay = tokensDataToDisplay.filter(
        ({tokenName, tokenData, networkA, networkB}) => {
            const text = textFilter.toLowerCase()

            const textCondition = textFilter === "" || tokenName.toLowerCase().includes(text)

            const {displayTokens} = filterData

            const alphabeticalNetworkPair =
                networkA && networkB
                    ? ([networkA, networkB]
                        .sort((a, b) => a.localeCompare(b))
                        .join("_") as `${NETWORK}_${NETWORK}`)
                    : undefined

            const checkForHidden = () => {
                const isTokenHidden = tokenData.hidden
                const isNetworkPairHidden =
                    alphabeticalNetworkPair &&
                    (tokenData.hiddenNetworkEntries?.[alphabeticalNetworkPair] === true ?? false)
                return isTokenHidden || isNetworkPairHidden
            }

            const hiddenFilter =
                displayTokens === "allall" || displayTokens === "all" ||
                Object.prototype.hasOwnProperty.call(pinnedTokens, tokenName) ||
                (displayTokens === "hidden" && checkForHidden()) ||
                (displayTokens === "unhidden" && !checkForHidden())

            return textCondition && hiddenFilter
        }
    )

    /**
     * Sorting
     */
    filteredTokensDataToDisplay.sort((a, b) => {
        const aTokenAlphabeticalNetworkPair = [a.networkA, a.networkB]
            .sort((a, b) => a.localeCompare(b))
            .join("_")
        const bTokenAlphabeticalNetworkPair = [b.networkA, b.networkB]
            .sort((a, b) => a.localeCompare(b))
            .join("_")

        if (dashboardDisplayAllPinnedTokens) {
            const tokenAPinned =
                Object.values(pinnedTokens).find((p) => p.tokenName === a.tokenName) ||
                Object.values(pinnedTokensNoTrade).find((p) => p.tokenName === a.tokenName)
            const tokenBPinned =
                Object.values(pinnedTokens).find((p) => p.tokenName === b.tokenName) ||
                Object.values(pinnedTokensNoTrade).find((p) => p.tokenName === b.tokenName)

            if (tokenAPinned && tokenBPinned) {
                const aPinnedAlphabeticalNetworkPair = [tokenAPinned.networkFrom, tokenAPinned.networkTo]
                    .sort((a, b) => a.localeCompare(b))
                    .join("_")
                const bPinnedAlphabeticalNetworkPair = [tokenBPinned.networkFrom, tokenBPinned.networkTo]
                    .sort((a, b) => a.localeCompare(b))
                    .join("_")

                const aActualTokenPairPinned =
                    aPinnedAlphabeticalNetworkPair === aTokenAlphabeticalNetworkPair
                const bActualTokenPairPinned =
                    bPinnedAlphabeticalNetworkPair === bTokenAlphabeticalNetworkPair

                // If both pinned - sort by pin time
                if (aActualTokenPairPinned && bActualTokenPairPinned) {
                    return tokenBPinned.pinnedAt - tokenAPinned.pinnedAt
                }
                if (aActualTokenPairPinned) {
                    console.log(
                        1,
                        a.tokenName,
                        b.tokenName,
                        aTokenAlphabeticalNetworkPair,
                        bTokenAlphabeticalNetworkPair
                    )
                    return -1
                }
                if (bActualTokenPairPinned) {
                    return 1
                }
            }
            if (tokenAPinned) {
                return -1
            }
            if (tokenBPinned) {
                return 1
            }
        } else {
            const tokenAPinned =
                Object.values(pinnedTokens).find((p) => {
                    return (
                        true &&
                        p.tokenName === a.tokenName &&
                        [p.networkFrom, p.networkTo].sort((a, b) => a.localeCompare(b)).join("_") ===
                        aTokenAlphabeticalNetworkPair
                    )
                }) ||
                Object.values(pinnedTokensNoTrade).find((p) => {
                    return (
                        true &&
                        p.tokenName === a.tokenName &&
                        [p.networkFrom, p.networkTo].sort((a, b) => a.localeCompare(b)).join("_") ===
                        aTokenAlphabeticalNetworkPair
                    )
                })
            const tokenBPinned =
                Object.values(pinnedTokens).find((p) => {
                    return (
                        true &&
                        p.tokenName === b.tokenName &&
                        [p.networkFrom, p.networkTo].sort((a, b) => a.localeCompare(b)).join("_") ===
                        bTokenAlphabeticalNetworkPair
                    )
                }) ||
                Object.values(pinnedTokensNoTrade).find((p) => {
                    return (
                        true &&
                        p.tokenName === b.tokenName &&
                        [p.networkFrom, p.networkTo].sort((a, b) => a.localeCompare(b)).join("_") ===
                        bTokenAlphabeticalNetworkPair
                    )
                })

            if (tokenAPinned && tokenBPinned) {
                return tokenBPinned.pinnedAt - tokenAPinned.pinnedAt
            }
            if (tokenAPinned) {
                return -1
            }
            if (tokenBPinned) {
                return 1
            }
        }

        if (a.trackingResult && b.trackingResult) {
            return parseFloat(
                BigNumber(b.trackingResult.profit).minus(BigNumber(a.trackingResult.profit)).toString()
            )
        }
        if (a.trackingResult) {
            return -1
        }
        if (b.trackingResult) {
            return 1
        }

        return a.tokenName.localeCompare(b.tokenName)
    })

    const data = filteredTokensDataToDisplay.map(
        ({tokenName, tokenData, networkA, networkB, trackingResult, req_key}) => {
            const [aClassName, bClassName] = getNetworkCellsClassNames(
                trackingResult?.networkResults?.[networkA || NETWORK.ETHEREUM],
                trackingResult?.networkResults?.[networkB || NETWORK.ETHEREUM]
            )

            let biggerPriceNetwork: NETWORK | undefined = undefined
            let lowerPriceNetwork: NETWORK | undefined = undefined

            if (networkA && networkB) {
                if (aClassName === "biggerPrice" && bClassName === "lowerPrice") {
                    biggerPriceNetwork = networkA
                    lowerPriceNetwork = networkB
                } else if (bClassName === "biggerPrice" && aClassName === "lowerPrice") {
                    biggerPriceNetwork = networkB
                    lowerPriceNetwork = networkA
                } else {
                    // For 1inch tracking, one of results is unavailable
                    const resA = trackingResult?.networkResults?.[networkA]
                    const resB = trackingResult?.networkResults?.[networkB]

                    if (resA) {
                        biggerPriceNetwork = networkA
                        lowerPriceNetwork = networkB
                    } else {
                        biggerPriceNetwork = networkB
                        lowerPriceNetwork = networkA
                    }
                }
            }

            const tokenNetworkPairPinned = Object.values(pinnedTokens).find((p) => {
                return (
                    p.tokenName === tokenName &&
                    ((p.networkFrom === networkA && p.networkTo === networkB) ||
                        (p.networkFrom === networkB && p.networkTo === networkA))
                )
            })

            const isTokenNetworkPairPinned = tokenNetworkPairPinned !== undefined

            const isTokenPinned =
                Object.values(pinnedTokens).find((p) => {
                    return p.tokenName === tokenName
                }) !== undefined

            const tokenNetworkPairPinnedNoTrade = Object.values(pinnedTokensNoTrade).find((p) => {
                return (
                    p.tokenName === tokenName &&
                    ((p.networkFrom === networkA && p.networkTo === networkB) ||
                        (p.networkFrom === networkB && p.networkTo === networkA))
                )
            })

            const isTokenNetworkPairPinnedNoTrade = tokenNetworkPairPinnedNoTrade !== undefined

            const isTokenPinnedNoTrade =
                Object.values(pinnedTokensNoTrade).find((p) => {
                    return p.tokenName === tokenName
                }) !== undefined

            // const profitNewHeads =
            // 	newHeads[
            // 	trackingResult?.reverse.route
            // 		.networkName as keyof typeof newHeads
            // 	];

            const alphabeticalNetworkPairArray =
                networkA && networkB ? [networkA, networkB].sort((a, b) => a.localeCompare(b)) : undefined
            const alphabeticalNetworkPair = alphabeticalNetworkPairArray
                ? (alphabeticalNetworkPairArray.join("_") as `${NETWORK}_${NETWORK}`)
                : undefined

            const autoSellPin = tokenNetworkPairPinned
                ? autosellPins[
                    `${tokenNetworkPairPinned.networkFrom}_${tokenNetworkPairPinned.networkTo}_${tokenNetworkPairPinned.tokenName}`
                    ]
                : undefined

            const networkCells = getNetworkCells(
                networkA,
                networkB,
                biggerPriceNetwork,
                trackingResult,
                tokenName,
                tokenData,
                filterData,
                aClassName,
                bClassName
            )

            return [
                <AutomationCell tokenName={tokenName} automation={tokenData.automation}/>,
                <PoopAutomationCell
                    tokenName={tokenName}
                    networkPairPoopAutomation={tokenData.networkPairPoopAutomation}
                    networkA={networkA}
                    networkB={networkB}
                />,
                <HideTokenCell
                    tokenName={tokenName}
                    isTokenHidden={tokenData.hidden === true}
                    alphabeticalNetworkPair={alphabeticalNetworkPair}
                    hiddenNetworkEntries={tokenData.hiddenNetworkEntries}
                    isTokenNetworkPairHidden={
                        alphabeticalNetworkPair
                            ? tokenData.hiddenNetworkEntries?.[alphabeticalNetworkPair] === true ?? false
                            : false
                    }
                    tokenOrTokenNetworkPair={alphabeticalNetworkPair ? "tokenNetworkPair" : "token"}
                />,
                <AutoSellCell
                    tokenName={tokenName}
                    isPinned={dashboardDisplayAllPinnedTokens ? isTokenPinned : isTokenNetworkPairPinned}
                    tokenNetworkPairPinned={tokenNetworkPairPinned}
                    autoSellPin={autoSellPin}
                />,
                <TokenCell
                    tokenName={tokenName}
                    isTokenPinned={isTokenPinned}
                    isTokenNetworkPairPinned={isTokenNetworkPairPinned}
                    isTokenPinnedNoTrade={isTokenPinnedNoTrade}
                    isTokenNetworkPairPinnedNoTrade={isTokenNetworkPairPinnedNoTrade}
                    dashboardDisplayAllPinnedTokens={dashboardDisplayAllPinnedTokens}
                    trackingValue={tokenData.trackingValue}
                    actualTrackingValue={trackingResult?.trackingValue}
                    tokenNetworkPairPinned={tokenNetworkPairPinned}
                    req_key={req_key}
                />,
                networkCells[0],
                networkCells[1],
                networkCells[2],
                networkCells[3],
                <SlippageCell
                    tokenName={tokenName}
                    slippage={tokenData.slippage}
                    networkPairSlippage={tokenData.networkPairSlippage}
                    tokenBridge={tokenData.bridge}
                    bridges={bridges}
                    networkA={biggerPriceNetwork || networkA}
                    networkB={lowerPriceNetwork || networkB}
                />,
                <TradeSlippageCell
                    tokenName={tokenName}
                    tradeSlippage={tokenData.tradeSlippage}
                    networkA={biggerPriceNetwork || networkA}
                    networkB={lowerPriceNetwork || networkB}
                />,
                <ProfitCell
                    tokenName={tokenName}
                    tokenData={tokenData}
                    gasEstimate={gasEstimate}
                    trackingResult={trackingResult}
                    biggerPriceNetwork={biggerPriceNetwork}
                    lowerPriceNetwork={lowerPriceNetwork}
                    networkNewHeads={undefined}
                    reverseResult={trackingResult?.reverse}
                    profit={trackingResult?.profit ?? ""}
                    displayRoutes={filterData.displayRoutes}
                    dexPosition={filterData.dexPosition}
                    float="right"
                />,
                <CommentsCell
                    tokenName={tokenName}
                    networkA={alphabeticalNetworkPairArray?.[0]}
                    networkB={alphabeticalNetworkPairArray?.[1]}
                    tokenData={tokenData}
                    tokenComments={tokenData.comments}
                />,
                <ForceSellCell
                    tokenName={tokenName}
                    isPinned={dashboardDisplayAllPinnedTokens ? isTokenPinned : isTokenNetworkPairPinned}
                    tokenNetworkPairPinned={tokenNetworkPairPinned}
                />,
                <PinnedCell
                    tokenName={tokenName}
                    isPinned={dashboardDisplayAllPinnedTokens ? isTokenPinned : isTokenNetworkPairPinned}
                    tokenNetworkPairPinned={tokenNetworkPairPinned}
                    isPinnedNoTrade={
                        dashboardDisplayAllPinnedTokens
                            ? isTokenPinnedNoTrade
                            : isTokenNetworkPairPinnedNoTrade
                    }
                    tokenNetworkPairPinnedNoTrade={tokenNetworkPairPinnedNoTrade}
                />,
            ]
        }
    )

    const totalNumTokens = tokenEntries.length
    const totalNumEntries = tokensDataToDisplay.length
    const filteredNumEntries = filteredTokensDataToDisplay.length

    return (
        <Stack gap={2}>
            <NumElementsBadge
                filteredNumEntries={filteredNumEntries}
                totalNumEntries={totalNumEntries}
                totalNumTokens={totalNumTokens}
                workersRunning={workersRunning}
            />
            <VirtualTable
                ref={virtualTableRef}
                columns={columns}
                data={data}
                estimateRowHeight={estimateRowHeight}
            />
        </Stack>
    )
})

function getNetworkCells(
    networkA: NETWORK,
    networkB: NETWORK,
    biggerPriceNetwork: NETWORK | undefined,
    trackingResult: WorkerIterationResult | undefined,
    tokenName: string,
    tokenData: DBToken,
    filterData: DashboardFilterData,
    aClassName: string,
    bClassName: string
) {
    const aHigher = biggerPriceNetwork === undefined || biggerPriceNetwork === networkA
    const networkAutomationCellA = (
        <NetworkAutomationCell
            tokenName={tokenName}
            networkFrom={networkA}
            networkTo={networkB}
            tokenData={tokenData}
        />
    )
    const networkAutomationCellB = (
        <NetworkAutomationCell
            tokenName={tokenName}
            networkFrom={networkB}
            networkTo={networkA}
            tokenData={tokenData}
        />
    )
    const networkCellA = (
        <NetworkCell
            updatedAt={trackingResult?.updatedAt}
            allowAutomation={trackingResult?.allowAutomation}
            disallowAutomationReason={trackingResult?.disallowAutomationReason}
            totalTimeFromLog={trackingResult?.totalTimeFromLog}
            // networkNewHeads={newHeads.Ethereum}
            networkNewHeads={undefined}
            networkResult={networkA ? trackingResult?.networkResults[networkA as NETWORK] : undefined}
            dexPosition={filterData.dexPosition}
            displayRoutes={filterData.displayRoutes}
            float={aHigher ? "left" : "right"}
            customClass={aClassName}
            tokenName={tokenName}
            networkTrackIgnore={tokenData.networkTrackIgnore}
            networkPairTrackIgnore={tokenData.networkPairTrackIgnore}
            networkName={networkA}
            anotherNetworkName={networkB}
            tokenAddress={networkA ? tokenData.networkData?.[networkA as NETWORK]?.address ?? "" : ""}
            trackingValue={tokenData.trackingValue}
            actualTrackingValue={trackingResult?.trackingValue}
        />
    )
    const networkCellB = (
        <NetworkCell
            updatedAt={trackingResult?.updatedAt}
            allowAutomation={trackingResult?.allowAutomation}
            disallowAutomationReason={trackingResult?.disallowAutomationReason}
            totalTimeFromLog={trackingResult?.totalTimeFromLog}
            // networkNewHeads={newHeads.Binance}
            networkNewHeads={undefined}
            networkResult={networkB ? trackingResult?.networkResults[networkB as NETWORK] : undefined}
            dexPosition={filterData.dexPosition}
            displayRoutes={filterData.displayRoutes}
            float={aHigher ? "right" : "left"}
            customClass={bClassName}
            tokenName={tokenName}
            networkTrackIgnore={tokenData.networkTrackIgnore}
            networkPairTrackIgnore={tokenData.networkPairTrackIgnore}
            networkName={networkB}
            anotherNetworkName={networkA}
            tokenAddress={networkB ? tokenData.networkData?.[networkB as NETWORK]?.address ?? "" : ""}
            trackingValue={tokenData.trackingValue}
            actualTrackingValue={trackingResult?.trackingValue}
        />
    )

    return [
        aHigher ? networkAutomationCellA : networkAutomationCellB,
        aHigher ? networkCellA : networkCellB,
        aHigher ? networkCellB : networkCellA,
        aHigher ? networkAutomationCellB : networkAutomationCellA,
    ]
}

/**
 *
 */
type NumElementsBadgeProps = {
    filteredNumEntries: number
    totalNumEntries: number
    totalNumTokens: number
    workersRunning: AppState["brainState"]["workersRunning"]
}
const NumElementsBadge = memo(function NumElementsBadge({
                                                            filteredNumEntries,
                                                            totalNumEntries,
                                                            totalNumTokens,
                                                            workersRunning,
                                                        }: NumElementsBadgeProps) {
    return (
        <div>
            <Badge bg="light" text="dark" className="h1" style={{fontSize: "0.8em"}}>
                {`Showing ${filteredNumEntries} / ${totalNumEntries} entries (${totalNumTokens} tokens)`}
            </Badge>
            {workersRunning === false && (
                <Badge
                    bg="light"
                    text="dark"
                    className="h1"
                    style={{marginLeft: "1em", fontSize: "0.8em", cursor: "pointer"}}
                    onClick={() => sendUpdateEvent(MODULE_EVENTS.CLEAR_CACHED_RESULTS, {})}
                >
                    {`Clear cached results for everyone`}
                </Badge>
            )}
        </div>
    )
})

/**
 *
 */
type AutomationCellProps = {
    tokenName: string
    automation: DBToken["automation"]
}
const AutomationCell = memo(function AutomationCell({tokenName, automation}: AutomationCellProps) {
    return (
        <div className="d-grid" style={{height: "100%"}}>
            <Button size="sm" variant="light" onClick={() => updateTokenAutomation(tokenName, automation)}>
                {automation ? "✅" : "⬛️"}
            </Button>
        </div>
    )
})

const NETWORK_COLOR: { [networkName in NETWORK]?: string } = {
    [NETWORK.ETHEREUM]: "rgba(104, 125, 227, 0.8)",
    [NETWORK.ARBITRUM]: "blue",
    [NETWORK.BINANCE]: "yellow",
    [NETWORK.POLYGON]: "purple",
}

/**
 *
 */
type PoopAutomationCellProps = {
    tokenName: string
    networkPairPoopAutomation: DBToken["networkPairPoopAutomation"]
    networkA: NETWORK
    networkB: NETWORK
}
const PoopAutomationCell = memo(function PoopAutomationCell({
                                                                tokenName,
                                                                networkPairPoopAutomation,
                                                                networkA,
                                                                networkB,
                                                            }: PoopAutomationCellProps) {
    const [hover, setHover] = useState(false)

    const poopAutomationValue = getTokenNetworkPairPoopAutomation(
        networkPairPoopAutomation,
        networkA,
        networkB
    )

    let currentBgColor = undefined

    const isPoopVisible = poopAutomationValue !== false

    return (
        <div className="d-grid" style={{height: "100%"}}>
            <div
                style={{display: "flex", zIndex: 1}}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
            >
                <Button
                    size="sm"
                    variant="outline-light"
                    style={{position: "relative", width: "100%", backgroundColor: currentBgColor}}
                    onClick={() =>
                        updateTokenNetworkPairPoopAutomation(
                            tokenName,
                            networkPairPoopAutomation,
                            networkA,
                            networkB,
                            "general"
                        )
                    }
                >
                    <div style={{position: "absolute", top: 0, left: 0, opacity: 0.3}}>
                        {isPoopVisible && poopAutomationValue !== true && (
                            <img width="18px" alt="" src={getNetworkImage(poopAutomationValue ?? "")}/>
                        )}
                    </div>
                    <div
                        style={{
                            zIndex: 1,
                            position: "relative",
                            opacity: poopAutomationValue === true ? 1 : 0.5,
                        }}
                    >
                        {isPoopVisible || hover ? "💩" : " "}
                    </div>
                </Button>
                {hover && (
                    <>
                        <Button
                            size="sm"
                            variant="outline-light"
                            style={{position: "relative"}}
                            onClick={() =>
                                updateTokenNetworkPairPoopAutomation(
                                    tokenName,
                                    networkPairPoopAutomation,
                                    networkA,
                                    networkB,
                                    "networkA"
                                )
                            }
                        >
                            <div
                                style={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    opacity: poopAutomationValue === networkA ? 1 : 0.3,
                                }}
                            >
                                <img width="18px" alt="" src={getNetworkImage(networkA ?? "")}/>
                            </div>
                            <div
                                style={{
                                    zIndex: 1,
                                    position: "relative",
                                    opacity: poopAutomationValue === networkA ? 1 : 0.5,
                                }}
                            >
                                {"💩"}
                            </div>
                        </Button>
                        <Button
                            size="sm"
                            variant="outline-light"
                            style={{position: "relative"}}
                            onClick={() =>
                                updateTokenNetworkPairPoopAutomation(
                                    tokenName,
                                    networkPairPoopAutomation,
                                    networkA,
                                    networkB,
                                    "networkB"
                                )
                            }
                        >
                            <div
                                style={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    opacity: poopAutomationValue === networkB ? 1 : 0.3,
                                }}
                            >
                                <img width="18px" alt="" src={getNetworkImage(networkB ?? "")}/>
                            </div>
                            <div
                                style={{
                                    zIndex: 1,
                                    position: "relative",
                                    opacity: poopAutomationValue === networkB ? 1 : 0.5,
                                }}
                            >
                                {"💩"}
                            </div>
                        </Button>
                    </>
                )}
            </div>
        </div>
    )
})

/**
 *
 */
type HideTokenCellProps = {
    tokenName: string
    isTokenHidden: boolean
    tokenOrTokenNetworkPair: "token" | "tokenNetworkPair"
    alphabeticalNetworkPair?: string
    hiddenNetworkEntries: DBToken["hiddenNetworkEntries"]
    isTokenNetworkPairHidden: boolean
} & ({} | {})
const HideTokenCell = memo(function HideTokenCell({
                                                      tokenName,
                                                      isTokenHidden,
                                                      alphabeticalNetworkPair,
                                                      hiddenNetworkEntries,
                                                      isTokenNetworkPairHidden,
                                                      tokenOrTokenNetworkPair,
                                                  }: HideTokenCellProps) {
    const ifHidden = (
        <Button
            size="sm"
            variant="light"
            onClick={() => {
                if (tokenOrTokenNetworkPair === "token") {
                    updateTokenHidden(tokenName, null)
                } else {
                    const newHiddenNetworkEntries = structuredClone(hiddenNetworkEntries || {})
                    delete newHiddenNetworkEntries[
                        alphabeticalNetworkPair as keyof typeof newHiddenNetworkEntries
                        ]
                    if (Object.keys(newHiddenNetworkEntries).length !== 0) {
                        updateTokenNetworkPairHidden(tokenName, newHiddenNetworkEntries)
                    } else {
                        updateTokenNetworkPairHidden(tokenName, null)
                    }
                }
            }}
        >
            {eyeSlash}
        </Button>
    )

    const [hover, setHover] = useState(false)
    const ifNotHidden = (
        <Button
            size="sm"
            variant="outline-light"
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onClick={() => {
                if (tokenOrTokenNetworkPair === "token") {
                    updateTokenHidden(tokenName, true)
                } else {
                    updateTokenNetworkPairHidden(tokenName, {
                        ...(hiddenNetworkEntries || {}),
                        [alphabeticalNetworkPair!]: true,
                    })
                }
            }}
        >
            {hover && eyeSlash}
        </Button>
    )

    const whatToDisplay = () => {
        if (isTokenHidden || isTokenNetworkPairHidden) return ifHidden
        return ifNotHidden
    }

    return (
        <div className="d-grid" style={{height: "100%"}}>
            {whatToDisplay()}
        </div>
    )
})

/**
 *
 */
type AutoSellCellProps = {
    tokenName: string
    isPinned: boolean
    tokenNetworkPairPinned?: AppState["brainState"]["pinnedTokens"]["any"]
    autoSellPin?: boolean
}
const AutoSellCell = memo(function AutoSellCell({
                                                    tokenName,
                                                    isPinned,
                                                    tokenNetworkPairPinned,
                                                    autoSellPin,
                                                }: AutoSellCellProps) {
    if (!isPinned) return <></>
    if (tokenNetworkPairPinned === undefined) return <></>

    return (
        <div className="d-grid" style={{height: "100%"}}>
            <Button
                size="sm"
                variant="light"
                onClick={() => {
                    if (autoSellPin) {
                        sendUpdateEvent(MODULE_EVENTS.UNPIN_TOKEN_AUTOSELL, {
                            tokenName,
                            networkBuy: tokenNetworkPairPinned.networkFrom,
                            networkSell: tokenNetworkPairPinned.networkTo,
                        })
                    } else {
                        sendUpdateEvent(MODULE_EVENTS.PIN_TOKEN_AUTOSELL, {
                            tokenName,
                            networkBuy: tokenNetworkPairPinned.networkFrom,
                            networkSell: tokenNetworkPairPinned.networkTo,
                        })
                    }
                    // updateTokenAutomation(tokenName, automation)
                }}
            >
                {autoSellPin === undefined ? "?" : autoSellPin ? "📴" : "✅"}
            </Button>
        </div>
    )
})

/**
 *
 */
type TokenCellProps = {
    tokenName: string
    isTokenPinned: boolean
    isTokenNetworkPairPinned: boolean
    isTokenPinnedNoTrade: boolean
    isTokenNetworkPairPinnedNoTrade: boolean
    dashboardDisplayAllPinnedTokens: boolean | undefined
    trackingValue: number
    actualTrackingValue?: string
    tokenNetworkPairPinned?: AppState["brainState"]["pinnedTokens"]["any"]
    req_key: string
}
const TokenCell = memo(function TokenCell({
                                              tokenName,
                                              isTokenPinned,
                                              isTokenNetworkPairPinned,
                                              isTokenPinnedNoTrade,
                                              isTokenNetworkPairPinnedNoTrade,
                                              dashboardDisplayAllPinnedTokens,
                                              trackingValue,
                                              actualTrackingValue,
                                              tokenNetworkPairPinned,
                                              req_key,
                                          }: TokenCellProps) {
    const getPin = () => {
        if (dashboardDisplayAllPinnedTokens) {
            if (isTokenNetworkPairPinned) return "📍📍"
            if (!isTokenNetworkPairPinned && isTokenPinned) return "📍"
        } else {
            if (isTokenNetworkPairPinned) return "📍"
        }
    }
    const getPinNoTrade = () => {
        if (dashboardDisplayAllPinnedTokens) {
            if (isTokenNetworkPairPinnedNoTrade) return "💩💩"
            if (!isTokenNetworkPairPinnedNoTrade && isTokenPinnedNoTrade) return "💩"
        } else {
            if (isTokenNetworkPairPinnedNoTrade) return "💩"
        }
    }
    // console.log(tokenName, isTokenPinnedNoTrade, isTokenNetworkPairPinnedNoTrade)
    return (
        <Badge bg="light" text="dark" className="noBg" style={{fontSize: "1em", position: "relative"}}>
			<span
                style={{
                    cursor: "pointer",
                    display:
                        (isTokenPinned && isTokenNetworkPairPinned) ||
                        (isTokenPinnedNoTrade && isTokenNetworkPairPinnedNoTrade)
                            ? "inline-block"
                            : "none",
                    position: "absolute",
                    left: 0,
                }}
                onClick={() => {
                    if (tokenNetworkPairPinned) {
                        sendUpdateEvent(MODULE_EVENTS.UNPIN_TOKEN, {tokenName})
                        sendUpdateEvent(MODULE_EVENTS.DELETE_TOKEN_AUTOSELL, {
                            tokenName,
                            networkBuy: tokenNetworkPairPinned.networkFrom,
                            networkSell: tokenNetworkPairPinned.networkTo,
                        })
                    }
                }}
            >
				{getPin()}
                {getPinNoTrade()}
			</span>
            {tokenName}
            <span
                style={{
                    position: "absolute",
                    fontSize: "0.8em",
                    top: 0,
                    right: "8px",
                }}
            >
				{actualTrackingValue ? parseFloat(parseFloat(actualTrackingValue).toFixed(3)) : ""}
			</span>
            <img
                style={{
                    position: "absolute",
                    right: "8px",
                    bottom: 0,
                }}
                width="11px"
                alt=""
                src={getAggregatorImage(req_key)}
            />
            {/* <span
				style={{
					position: "absolute",
					fontSize: "0.5em",
					opacity: 0.5,
					bottom: 0,
					right: "8px",
				}}
			>
				{trackingValue}
			</span> */}
        </Badge>
    )
})

/**
 *
 */
type NetworkAutomationCellProps = {
    tokenName: string
    networkFrom?: NETWORK
    networkTo?: NETWORK
    tokenData: DBToken
}
const NetworkAutomationCell = memo(function NetworkAutomationCell({
                                                                      tokenName,
                                                                      networkFrom,
                                                                      networkTo,
                                                                      tokenData,
                                                                  }: NetworkAutomationCellProps) {
    if (networkFrom && networkTo) {
        const curValue = tokenData.networkPairAutomation?.[`${networkFrom}_${networkTo}`] === true
        return (
            <div className="d-grid" style={{height: "100%"}}>
                <Button
                    size="sm"
                    variant="light"
                    onClick={() =>
                        updateTokenNetworkPairAutomation(
                            tokenName,
                            curValue,
                            networkFrom,
                            networkTo,
                            tokenData
                        )
                    }
                >
                    {curValue ? "✅" : "⬛️"}
                </Button>
            </div>
        )
    }
    return <div className="d-grid" style={{height: "100%"}}></div>
})

/**
 *
 */
type NetworkCellProps = {
    updatedAt?: number
    allowAutomation?: boolean
    disallowAutomationReason?: string
    totalTimeFromLog?: number
    networkNewHeads?: AppState["newHeads"]["Ethereum"]
    networkResult?: WorkerIterationResult["networkResults"]["Ethereum"]
    dexPosition: DashboardFilterData["dexPosition"]
    displayRoutes: DashboardFilterData["displayRoutes"]
    float: "left" | "right"
    customClass: string
    tokenName: string
    networkTrackIgnore: DBToken["networkTrackIgnore"]
    networkPairTrackIgnore: DBToken["networkPairTrackIgnore"]
    networkName: NETWORK
    anotherNetworkName: NETWORK
    tokenAddress: string
    trackingValue: number
    actualTrackingValue?: string
}
const NetworkCell = memo(function NetworkCell({
                                                  updatedAt,
                                                  allowAutomation,
                                                  disallowAutomationReason,
                                                  totalTimeFromLog,
                                                  networkNewHeads,
                                                  networkResult,
                                                  dexPosition,
                                                  displayRoutes,
                                                  float,
                                                  customClass,
                                                  tokenName,
                                                  networkTrackIgnore,
                                                  networkPairTrackIgnore,
                                                  networkName,
                                                  anotherNetworkName,
                                                  tokenAddress,
                                                  trackingValue,
                                                  actualTrackingValue,
                                              }: NetworkCellProps) {
    const NetworkStack = useMemo(() => {
        if (!networkName) return <></>

        let disallowAutomationSymbol = "🟤"
        if (disallowAutomationReason === "!active") disallowAutomationSymbol = "🔴"
        if (disallowAutomationReason === "!allowAutomation") disallowAutomationSymbol = "🟠"
        if (disallowAutomationReason === "!networkPairAutomation") disallowAutomationSymbol = "🟡"
        if (disallowAutomationReason === "poop") disallowAutomationSymbol = "💩"

        return (
            <Stack direction="horizontal" gap={1} style={{float: float}}>
                <img width="18px" alt="" src={getNetworkImage(networkName ?? "")}/>
                {allowAutomation === false && updatedAt && <span style={{fontSize: "0.8em"}}>{disallowAutomationSymbol}</span>}
            </Stack>
        )
    }, [float, networkName, allowAutomation, updatedAt])

    const DexLeftStack = useMemo(() => {
        const [dexName, dexVersion] = getDexNameAndVersion(networkResult?.route?.dexName ?? "")
        if (dexName === "" && dexVersion === "") {
            return <></>
        }
        if (float === "left")
            return (
                <Stack direction="horizontal" gap={1} style={{float: "left", marginLeft: "3px"}}>
                    <img width="14px" alt="" src={getDexImage(dexName)}/>
                    <span
                        style={{
                            fontSize: "0.7em",
                            color: "var(--bs-gray-600)",
                        }}
                    >
						v{dexVersion}
					</span>
                </Stack>
            )
        if (float === "right")
            return (
                <Stack direction="horizontal" gap={1} style={{float: "right", marginRight: "3px"}}>
					<span
                        style={{
                            fontSize: "0.7em",
                            color: "var(--bs-gray-600)",
                        }}
                    >
						v{dexVersion}
					</span>
                    <img width="14px" alt="" src={getDexImage(dexName)}/>
                </Stack>
            )
        return <></>
    }, [float, networkResult])

    const DexBottomStack = useMemo(() => {
        const [dexName, dexVersion] = getDexNameAndVersion(networkResult?.route?.dexName ?? "")
        if (dexName === "" && dexVersion === "") {
            return <></>
        }
        return (
            <Stack direction="horizontal" gap={1} style={{justifyContent: "center"}}>
                <span style={{color: "var(--bs-gray-600)"}}>{dexName}</span>
                <span style={{color: "var(--bs-gray-600)"}}>v{dexVersion}</span>
            </Stack>
        )
    }, [networkResult])

    const RoutesStack = useMemo(() => {
        return (
            <Stack direction="horizontal" gap={1} style={{justifyContent: "center"}}>
                {networkResult?.route?.path?.map((v, i) => (
                    <div key={v[0]}>
						<span style={{color: "var(--bs-gray-600)"}}>
							{v[0]} {/* tokenName */}
						</span>
                        {i < (networkResult.route?.path || []).length - 1 && (
                            <span
                                style={{
                                    fontSize: "0.75em",
                                    color: "var(--bs-gray-600)",
                                }}
                            >
								{networkResult.route?.fees ? `${networkResult.route?.fees[i] / 10000}%` : ">"}{" "}
                                {/* fees */}
							</span>
                        )}
                    </div>
                ))}
            </Stack>
        )
    }, [networkResult])

    const BNStack = useMemo(() => {
        const bn = parseInt(networkResult?.blockNumber.toString() ?? "0")
        return (
            <Stack direction="horizontal" gap={1} style={{justifyContent: "center"}}>
                <div>
					<span
                        style={{
                            color:
                                networkNewHeads && bn !== networkNewHeads.blockNumber
                                    ? "var(--bs-danger)"
                                    : "var(--bs-gray-600)",
                        }}
                    >
						{bn}
					</span>
                </div>
            </Stack>
        )
    }, [networkResult, networkNewHeads])

    // if (!networkResult) return <></>

    // const destTokenDecimals = networkResult?.route.path[networkResult?.route.path.length - 1][2]
    // const adjustedReturnAmount = BigNumber(networkResult?.returnAmount ?? "0").div(
    // 	10 ** (destTokenDecimals || 1)
    // )

    // const isTrackingIgnored = getTokenNetworkPairTrackIgnore(
    // 	networkPairTrackIgnore,
    // 	networkName,
    // 	anotherNetworkName,
    // 	networkName
    // )

    let isTrackingIgnored = getTokenNetworkTrackIgnore(networkTrackIgnore, networkName)

    {
        /* 1inch tracking - comment next line */
    }
    isTrackingIgnored = false

    const UpdateTimeComp = memo(function UpdateTimeComp({updatedAt}: { updatedAt?: number }) {
        const [count, setCount] = useState(0)

        useEffect(() => {
            const interval = setInterval(() => {
                setCount(count + 1)
            }, 1000)

            return () => clearInterval(interval)
        }, [count])

        const updatedAtString = Math.min((Date.now() - (updatedAt || Date.now())) / 1000, 999).toFixed(0)

        return <>{updatedAtString}</>
    })

    let width = columns.find((c) => c.name === "Network")?.minWidth ?? 200
    if (float === "right") {
        width = columns.findLast((c) => c.name === "Network")?.minWidth ?? 100
    }

    return (
        <Stack style={{position: "relative"}}>
            <div className="networkButtonShareBlock">
                <div
                    className="networkButtonShareBlockInner"
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-end",
                        justifyContent: "space-between",
                    }}
                    onClick={() => {
                        window.open(
                            `${
                                NETWORK_EXPLORER_ADDRESS[networkName as keyof typeof NETWORK_EXPLORER_ADDRESS]
                            }token/${tokenAddress}`
                        )
                    }}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 30 30"
                        strokeWidth="1.5"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                        />
                    </svg>
                    {networkResult && updatedAt && (
                        <span style={{fontSize: "0.5em", color: "gray"}}>
							{/* {updatedAtString} */}
                            {<UpdateTimeComp updatedAt={updatedAt}/>}
                            <span style={{fontSize: "0.7em"}}>s</span>
						</span>
                    )}
                    {!networkResult && totalTimeFromLog && (
                        <span style={{fontSize: "0.5em", color: "gray"}}>
							{/* {updatedAtString} */}
                            {totalTimeFromLog}
                            <span style={{fontSize: "0.7em"}}>ms</span>
						</span>
                    )}
                </div>
            </div>
            <Button
                style={{
                    width: `${width - 4}px`,
                    paddingRight: "24px",
                }}
                variant="light"
                className={`noBorder ${customClass} text-truncate`}
                onClick={(e) => {
                    if (e.metaKey && e.shiftKey) {
                        // return toggleTokenNetworkPairTrackIgnore(
                        // 	tokenName,
                        // 	networkPairTrackIgnore,
                        // 	networkName,
                        // 	anotherNetworkName,
                        // 	networkName
                        // )

                        return toggleTokenNetworkTrackIgnore(tokenName, networkTrackIgnore, networkName)
                    }

                    if (!networkResult && e.metaKey) {
                        return sendUpdateEvent(MODULE_EVENTS.COMMAND, {
                            to: "worker",
                            cmd: `track~${tokenName}~${networkName}~${anotherNetworkName}`,
                        })
                    }

                    if (networkName === NETWORK.SOLANA) {
                        return window.open(
                            `https://jup.ag/swap/${NETWORK_SOURCE_TOKEN[networkName][1]}-${tokenAddress}`,
                            "_blank"
                        )
                    }

                    const amount = actualTrackingValue || trackingValue || 1
                    // window.open(
                    //     `https://app.1inch.io/#/${NETWORK_NAME_TO_CHAIN_ID[networkName]}/advanced/swap/${NETWORK_SOURCE_TOKEN[networkName][1]}/${tokenAddress}?sourceTokenAmount=${amount}`,
                    //     "_blank"
                    // )
                    window.open(
                        `https://swap.defillama.com/?chain=${defillamaNetworks[networkName]}&from=${NETWORK_SOURCE_TOKEN[networkName][1]}&tab=swap&to=${tokenAddress}`,
                        "_blank"
                    )
                    return

                    // TODO: Refactor and move to separate function
                    const [dexName, dexVersion] = getDexNameAndVersion(networkResult?.route?.dexName ?? "")
                    if (!dexName) return

                    if (networkName === NETWORK.ETHEREUM) {
                        if (dexName === "Uniswap") {
                            window.open(
                                `https://app.uniswap.org/#/swap?chain=eth&inputCurrency=0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2&outputCurrency=${tokenAddress}&exactField=input&exactAmount=${
                                    actualTrackingValue || trackingValue
                                }`
                            )
                        } else if (dexName === "Sushiswap") {
                            window.open(
                                `https://ethereum.sushi.com/swap?inputCurrency=0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2&outputCurrency=${tokenAddress}&exactField=input&exactAmount=${
                                    actualTrackingValue || trackingValue
                                }`
                            )
                        } else if (dexName === "Shibaswap") {
                            window.open(
                                `https://shibaswap.com/#/swap?inputCurrency=0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2&outputCurrency=${tokenAddress}&exactField=input&exactAmount=${
                                    actualTrackingValue || trackingValue
                                }`
                            )
                        } else if (dexName === "Pancakeswap") {
                            window.open(
                                `https://pancakeswap.finance/swap?chain=eth&inputCurrency=0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2&outputCurrency=${tokenAddress}&exactField=input&exactAmount=${
                                    actualTrackingValue || trackingValue
                                }`
                            )
                        } else if (dexName === "Apeswap") {
                            window.open(
                                `https://apeswap.finance/swap?chain=ethereum&inputCurrency=0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2&outputCurrency=${tokenAddress}&exactField=input&exactAmount=${
                                    actualTrackingValue || trackingValue
                                }`
                            )
                        }
                    } else if (networkName === NETWORK.BINANCE) {
                        if (dexName === "Uniswap") {
                            window.open(
                                `https://app.uniswap.org/#/swap?chain=bnb&inputCurrency=0x2170ed0880ac9a755fd29b2688956bd959f933f8&outputCurrency=${tokenAddress}&exactField=input&exactAmount=${
                                    actualTrackingValue || trackingValue
                                }`
                            )
                        } else if (dexName === "Sushiswap") {
                            window.open(
                                `https://bsc.sushi.com/swap?inputCurrency=0x2170ed0880ac9a755fd29b2688956bd959f933f8&outputCurrency=${tokenAddress}&exactField=input&exactAmount=${
                                    actualTrackingValue || trackingValue
                                }`
                            )
                        } else if (dexName === "Pancakeswap") {
                            window.open(
                                `https://pancakeswap.finance/swap?chain=bnb&inputCurrency=0x2170ed0880ac9a755fd29b2688956bd959f933f8&outputCurrency=${tokenAddress}&exactField=input&exactAmount=${
                                    actualTrackingValue || trackingValue
                                }`
                            )
                        } else if (dexName === "TraderJoe") {
                            window.open(
                                `https://traderjoexyz.com/bnb-chain/trade?inputCurrency=0x2170ed0880ac9a755fd29b2688956bd959f933f8&outputCurrency=${tokenAddress}&exactField=input&exactAmount=${
                                    actualTrackingValue || trackingValue
                                }`
                            )
                        } else if (dexName === "Apeswap") {
                            window.open(
                                `https://apeswap.finance/swap?chain=bnb&inputCurrency=0x2170ed0880ac9a755fd29b2688956bd959f933f8&outputCurrency=${tokenAddress}&exactField=input&exactAmount=${
                                    actualTrackingValue || trackingValue
                                }`
                            )
                        } else if (dexName === "Bakeryswap") {
                            window.open(
                                `https://www.bakeryswap.org/#/swap?inputCurrency=0x2170ed0880ac9a755fd29b2688956bd959f933f8&outputCurrency=${tokenAddress}&exactField=input&exactAmount=${
                                    actualTrackingValue || trackingValue
                                }`
                            )
                        } else if (dexName === "Biswap") {
                            window.open(
                                `https://exchange.biswap.org/#/swap?inputCurrency=0x2170ed0880ac9a755fd29b2688956bd959f933f8&outputCurrency=${tokenAddress}&exactField=input&exactAmount=${
                                    actualTrackingValue || trackingValue
                                }`
                            )
                        } else if (dexName === "Cafeswap") {
                            window.open(
                                `https://cafeswap.app/#/swap?inputCurrency=0x2170ed0880ac9a755fd29b2688956bd959f933f8&outputCurrency=${tokenAddress}&exactField=input&exactAmount=${
                                    actualTrackingValue || trackingValue
                                }`
                            )
                        }
                    } else if (networkName === NETWORK.ARBITRUM) {
                        if (dexName === "Uniswap") {
                            window.open(
                                `https://app.uniswap.org/#/swap?chain=arbitrum&inputCurrency=0x82aF49447D8a07e3bd95BD0d56f35241523fBab1&outputCurrency=${tokenAddress}&exactField=input&exactAmount=${
                                    actualTrackingValue || trackingValue
                                }`
                            )
                        } else if (dexName === "TraderJoe") {
                            window.open(
                                `https://traderjoexyz.com/arbitrum/trade?inputCurrency=0x82aF49447D8a07e3bd95BD0d56f35241523fBab1&outputCurrency=${tokenAddress}&exactField=input&exactAmount=${
                                    actualTrackingValue || trackingValue
                                }`
                            )
                        } else if (dexName === "Sushiswap") {
                            window.open(
                                `https://arbitrum.sushi.com/swap?inputCurrency=0x82aF49447D8a07e3bd95BD0d56f35241523fBab1&outputCurrency=${tokenAddress}&exactField=input&exactAmount=${
                                    actualTrackingValue || trackingValue
                                }`
                            )
                        } else if (dexName === "Pancakeswap") {
                            window.open(
                                `https://pancakeswap.finance/swap?chain=arb&inputCurrency=0x82aF49447D8a07e3bd95BD0d56f35241523fBab1&outputCurrency=${tokenAddress}&exactField=input&exactAmount=${
                                    actualTrackingValue || trackingValue
                                }`
                            )
                        } else if (dexName === "Apeswap") {
                            window.open(
                                `https://apeswap.finance/swap?chain=arbitrum&inputCurrency=0x82aF49447D8a07e3bd95BD0d56f35241523fBab1&outputCurrency=${tokenAddress}&exactField=input&exactAmount=${
                                    actualTrackingValue || trackingValue
                                }`
                            )
                        }
                    } else if (networkName === NETWORK.POLYGON) {
                        if (dexName === "Uniswap") {
                            window.open(
                                `https://app.uniswap.org/#/swap?chain=polygon&inputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619&outputCurrency=${tokenAddress}&exactField=input&exactAmount=${
                                    actualTrackingValue || trackingValue
                                }`
                            )
                        } else if (dexName === "Sushiswap") {
                            // window.open(
                            // 	`https://polygon.sushi.com/swap?inputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619&outputCurrency=${tokenAddress}&exactField=input&exactAmount=${
                            // 		actualTrackingValue || trackingValue
                            // 	}`
                            // )
                        } else if (dexName === "Quickswap") {
                            window.open(
                                `https://quickswap.exchange/#/swap?swapIndex=0&currency0=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619&currency1=${tokenAddress}&exactAmount=${
                                    actualTrackingValue || trackingValue
                                }`
                            )
                        } else if (dexName === "Apeswap") {
                            window.open(
                                `https://apeswap.finance/swap?chain=arbitrum&inputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619&outputCurrency=${tokenAddress}&exactField=input&exactAmount=${
                                    actualTrackingValue || trackingValue
                                }`
                            )
                        }
                    }
                }}
            >
                {NetworkStack}
                {networkResult?.returnAmount !== undefined && dexPosition === "left" && DexLeftStack}
                <span style={{padding: "0 6px"}}>
					{/* {isTrackingIgnored ? "❌" : ""}
					{networkResult?.returnAmount !== undefined ? networkResult.returnAmountShifted : "‎ "} */}

                    {isTrackingIgnored
                        ? "❌"
                        : networkResult?.returnAmount !== undefined
                            ? networkResult.returnAmountShifted
                            : "‎ "}

                    {/* {networkResult?.returnAmount !== undefined
					? adjustedReturnAmount.toFixed(3)
					: "‎ "} */}
				</span>
            </Button>
            <Badge bg="light" text="dark" className="noBgHov noBorder" style={{fontSize: "0.6em"}}>
                {networkResult?.returnAmount !== undefined && dexPosition === "bottom" && DexBottomStack}
                {networkResult?.returnAmount !== undefined && displayRoutes === "true" && RoutesStack}
                {networkResult?.returnAmount !== undefined && displayRoutes === "bn" && BNStack}
            </Badge>
        </Stack>
    )
})

/**
 *
 */
type SlippageCellProps = {
    tokenName: string
    slippage: DBToken["slippage"]
    networkPairSlippage: DBToken["networkPairSlippage"]
    tokenBridge: DBToken["bridge"]
    bridges: DBBridges
    networkA: NETWORK
    networkB: NETWORK
}
const SlippageCell = memo(function SlippageCell({
                                                    tokenName,
                                                    slippage,
                                                    networkPairSlippage,
                                                    tokenBridge,
                                                    bridges,
                                                    networkA,
                                                    networkB,
                                                }: SlippageCellProps) {
    const bridgeNamesSortedByPriority = getBridgeNamesByTokenNetworkPairSortedByPriority(
        tokenBridge,
        networkA,
        networkB
    )

    const networksKey = [networkA, networkB].sort((a, b) => a.localeCompare(b)).join("_")
    const ref = useRef<HTMLButtonElement>(null)

    return (
        <div className="d-grid" style={{position: "relative", height: "100%"}}>
            <Button
                ref={ref}
                variant="light"
                className="noBgHov noBorder"
                style={{position: "relative"}}
                onClick={(e) => {
                    if (e.shiftKey) {
                        updateTokenSlippage(tokenName, slippage)
                        return
                    }
                    if (bridgeNamesSortedByPriority.length > 0) {
                        return window.open(bridges[bridgeNamesSortedByPriority[0]].url, "_blank")
                    }
                }}
            >
				<span
                    style={{
                        position: "absolute",
                        width: "100%",
                        left: "0",
                        top: "0",
                        fontSize: "0.9em",
                    }}
                >
					{slippage}
				</span>
                <span
                    className="text-truncate"
                    style={{
                        position: "absolute",
                        display: "block",
                        fontSize: "0.6em",
                        width: "100%",
                        left: "0",
                        bottom: "0",
                    }}
                >
					{bridgeNamesSortedByPriority.join(", ")}
				</span>
            </Button>
        </div>
    )
})
// type SlippageCellProps = {
//     tokenName: string
//     slippage: DBToken["slippage"]
//     networkPairSlippage: DBToken["networkPairSlippage"]
//     tokenBridge: DBToken["bridge"]
//     bridges: DBBridges
//     networkA: NETWORK
//     networkB: NETWORK
// }
// const SlippageCell = memo(function SlippageCell({
//                                                     tokenName,
//                                                     slippage,
//                                                     networkPairSlippage,
//                                                     tokenBridge,
//                                                     bridges,
//                                                     networkA,
//                                                     networkB,
//                                                 }: SlippageCellProps) {
//     const bridgeNamesSortedByPriority = getBridgeNamesByTokenNetworkPairSortedByPriority(
//         tokenBridge,
//         networkA,
//         networkB
//     )

//     const networksKey = [networkA, networkB].sort((a, b) => a.localeCompare(b)).join("_")
//     const actualSlippage = getTokenNetworkPairSlippage(slippage, networkPairSlippage, networkA, networkB)
//     const ref = useRef<HTMLButtonElement>(null)

//     return (
//         <div className="d-grid" style={{position: "relative", height: "100%"}}>
//             <Button
//                 variant="light"
//                 className="noBgHov noBorder"
//                 style={{
//                     zIndex: 2,
//                     position: "absolute",
//                     width: "25%",
//                     height: "100%",
//                     right: 0,
//                     top: 0,
//                 }}
//                 onClick={(e) => {
//                     if (e.shiftKey && e.metaKey) {
//                         updateTokenSlippage(tokenName, slippage)
//                         return
//                     }
//                     if (e.shiftKey) {
//                         editTokenNetworkSlippage(tokenName, networksKey, networkB, networkPairSlippage)
//                         return
//                     }
//                     ref.current?.click()
//                 }}
//             >
//                 {actualSlippage.type === "nps" && (
//                     <>
//                         <img
//                             style={{position: "absolute", top: "2px", right: "2px", zIndex: 0}}
//                             width="8px"
//                             alt=""
//                             src={getNetworkImage(networkB)}
//                         />
//                         <span style={{position: "absolute", top: "0px", right: "12px", fontSize: "0.5rem"}}>
// 							{actualSlippage.rValue}
// 						</span>
//                         <span
//                             style={{
//                                 position: "absolute",
//                                 bottom: "0px",
//                                 right: "2px",
//                                 fontSize: "0.5rem",
//                                 color: "#afafaf",
//                             }}
//                         >
// 							{slippage}
// 						</span>
//                     </>
//                 )}
//             </Button>
//             <Button
//                 ref={ref}
//                 variant="light"
//                 className="noBgHov noBorder"
//                 style={{position: "relative"}}
//                 onClick={(e) => {
//                     if (e.shiftKey && e.metaKey) {
//                         updateTokenSlippage(tokenName, slippage)
//                         return
//                     }
//                     if (e.shiftKey) {
//                         editTokenNetworkSlippage(tokenName, networksKey, networkA, networkPairSlippage)
//                         return
//                     }
//                     if (bridgeNamesSortedByPriority.length > 0) {
//                         return window.open(bridges[bridgeNamesSortedByPriority[0]].url, "_blank")
//                     }
//                 }}
//             >
// 				<span
//                     style={{
//                         position: "absolute",
//                         width: "100%",
//                         left: "0",
//                         top: "0",
//                         fontSize: "0.9em",
//                     }}
//                 >
// 					<Stack
//                         style={{
//                             display: actualSlippage.type === "nps" ? "block" : "none",
//                             position: "absolute",
//                             left: "2px",
//                             top: "2px",
//                         }}
//                     >
// 						<img
//                             style={{position: "absolute", top: "0", zIndex: 1}}
//                             width="12px"
//                             alt=""
//                             src={getNetworkImage(networkA)}
//                         />
// 					</Stack>
//                     {actualSlippage.gValue}
// 				</span>
//                 <span
//                     className="text-truncate"
//                     style={{
//                         position: "absolute",
//                         display: "block",
//                         fontSize: "0.6em",
//                         width: "100%",
//                         left: "0",
//                         bottom: "0",
//                     }}
//                 >
// 					{bridgeNamesSortedByPriority.join(", ")}
// 				</span>
//             </Button>
//         </div>
//     )
// })

/**
 *
 */
type TradeSlippageCellProps = {
    tokenName: string
    tradeSlippage: DBToken["tradeSlippage"]
    networkA: NETWORK
    networkB: NETWORK
}
const TradeSlippageCell = memo(function TradeSlippageCell({
                                                              tokenName,
                                                              tradeSlippage,
                                                              networkA,
                                                              networkB,
                                                          }: TradeSlippageCellProps) {
    const aSlipp = tradeSlippage?.[networkA] ?? 0
    const bSlipp = tradeSlippage?.[networkB] ?? 0
    return (
        <div className="d-grid" style={{position: "relative", height: "100%"}}>
            <Stack direction="horizontal" style={{justifyContent: "space-between"}} gap={1}>
                <Button
                    size="sm"
                    variant="light"
                    className="noBgHov noBorder"
                    style={{paddingLeft: 0, paddingRight: 0, opacity: aSlipp ? 1 : 0.1}}
                    onClick={() => editTokenTradeSlippage(tokenName, networkA as NETWORK, tradeSlippage)}
                >
                    <img src={getNetworkImage(networkA)} width="12px" alt=""/>
                    <span style={{fontSize: "0.7em", marginLeft: "3px"}}>{aSlipp}</span>
                </Button>
                <Button
                    size="sm"
                    variant="light"
                    className="noBgHov noBorder"
                    style={{paddingLeft: 0, paddingRight: 0, opacity: bSlipp ? 1 : 0.1}}
                    onClick={() => editTokenTradeSlippage(tokenName, networkB as NETWORK, tradeSlippage)}
                >
                    <span style={{fontSize: "0.7em", marginRight: "3px"}}>{bSlipp}</span>
                    <img src={getNetworkImage(networkB)} width="12px" alt=""/>
                </Button>
            </Stack>
        </div>
    )
})

/**
 *
 */
type ProfitCellProps = {
    tokenName: string
    tokenData: DBToken
    gasEstimate: AppState["gasEstimate"]
    trackingResult?: WorkerIterationResult
    biggerPriceNetwork: NETWORK | undefined
    lowerPriceNetwork: NETWORK | undefined
    networkNewHeads?: AppState["newHeads"]["Ethereum"]
    reverseResult?: WorkerIterationResult["reverse"]
    profit: WorkerIterationResult["profit"]
    displayRoutes: DashboardFilterData["displayRoutes"]
    dexPosition: DashboardFilterData["dexPosition"]
    float: "left" | "right"
}
const ProfitCell = memo(function ProfitCell({
                                                tokenName,
                                                tokenData,
                                                gasEstimate,
                                                trackingResult,
                                                biggerPriceNetwork,
                                                lowerPriceNetwork,
                                                networkNewHeads,
                                                reverseResult,
                                                profit,
                                                displayRoutes,
                                                dexPosition,
                                                float,
                                            }: ProfitCellProps) {
    let displayValue = "‎ "
    if (profit) {
        // displayValue = BigNumber(profit).toFixed(3);
        displayValue = parseFloat(BigNumber(profit).toFixed(4)).toString()
    }

    let networkName = reverseResult?.route?.networkName
    if (!networkName) {
        // In case of using 1inch
        networkName = lowerPriceNetwork
    }

    const NetworkStack = useMemo(() => {
        if (!networkName) return <></>

        return (
            <Stack direction="horizontal" gap={1} style={{float: float}}>
                <img width="18px" alt="" src={getNetworkImage(networkName)}/>
            </Stack>
        )
    }, [float, networkName])

    const DexLeftStack = useMemo(() => {
        const [dexName, dexVersion] = getDexNameAndVersion(reverseResult?.route?.dexName ?? "")
        if (dexName === "" && dexVersion === "") {
            return <></>
        }
        if (float === "left")
            return (
                <Stack direction="horizontal" gap={1} style={{float: "left", marginLeft: "6px"}}>
                    <img width="14px" alt="" src={getDexImage(dexName)}/>
                    <span
                        style={{
                            fontSize: "0.7em",
                            color: "var(--bs-gray-600)",
                        }}
                    >
						v{dexVersion}
					</span>
                </Stack>
            )
        if (float === "right")
            return (
                <Stack direction="horizontal" gap={1} style={{float: "right", marginRight: "6px"}}>
					<span
                        style={{
                            fontSize: "0.7em",
                            color: "var(--bs-gray-600)",
                        }}
                    >
						v{dexVersion}
					</span>
                    <img width="14px" alt="" src={getDexImage(dexName)}/>
                </Stack>
            )
        return <></>
    }, [float, reverseResult])

    const DexBottomStack = useMemo(() => {
        const [dexName, dexVersion] = getDexNameAndVersion(reverseResult?.route?.dexName ?? "")
        if (dexName === "" && dexVersion === "") {
            return <></>
        }
        return (
            <Stack direction="horizontal" gap={1} style={{justifyContent: "center"}}>
                <span style={{color: "var(--bs-gray-600)"}}>{dexName}</span>
                <span style={{color: "var(--bs-gray-600)"}}>v{dexVersion}</span>
            </Stack>
        )
    }, [reverseResult])

    const RoutesStack = useMemo(() => {
        return (
            <Stack direction="horizontal" gap={1} style={{justifyContent: "center"}}>
                {reverseResult?.route?.path?.map((v, i) => (
                    <div key={v[0]}>
						<span style={{color: "var(--bs-gray-600)"}}>
							{v[0]} {/* tokenName */}
						</span>
                        {i < (reverseResult.route?.path ?? []).length - 1 && (
                            <span
                                style={{
                                    fontSize: "0.75em",
                                    color: "var(--bs-gray-600)",
                                }}
                            >
								{reverseResult.route?.fees ? `${reverseResult.route?.fees[i] / 10000}%` : ">"}{" "}
                                {/* fees */}
							</span>
                        )}
                    </div>
                ))}
            </Stack>
        )
    }, [reverseResult])

    const BNStack = useMemo(() => {
        return (
            <Stack direction="horizontal" gap={1} style={{justifyContent: "center"}}>
                <div>
					<span
                        style={{
                            color:
                                (reverseResult?.blockNumber ?? 0) !== networkNewHeads?.blockNumber
                                    ? "var(--bs-danger)"
                                    : "var(--bs-gray-600)",
                        }}
                    >
						{reverseResult?.blockNumber}
					</span>
                </div>
            </Stack>
        )
    }, [reverseResult, networkNewHeads])

    return (
        <Stack>
            <Button
                variant="light"
                className="noBorder text-truncate"
                onClick={(e) => {
                    const {metaKey, shiftKey} = e
                    if (!metaKey && !shiftKey) {
                        if (!biggerPriceNetwork || !lowerPriceNetwork) return

                        const amount =
                            trackingResult?.networkResults?.[biggerPriceNetwork]?.returnAmountShifted ?? 1
                        const tokenAddress = tokenData.networkData?.[lowerPriceNetwork]?.address ?? "ETH"
                        // window.open(
                        //     `https://app.1inch.io/#/${NETWORK_NAME_TO_CHAIN_ID[lowerPriceNetwork]}/advanced/swap/${tokenAddress}/${NETWORK_SOURCE_TOKEN[lowerPriceNetwork][1]}?sourceTokenAmount=${amount}`,
                        //     "_blank"
                        // )
                        if (networkName === NETWORK.SOLANA) {
                            return window.open(
                                `https://jup.ag/swap/${tokenAddress}-${NETWORK_SOURCE_TOKEN[networkName][1]}`,
                                "_blank"
                            )
                        }
                        window.open(
                            `https://swap.defillama.com/?chain=${defillamaNetworks[lowerPriceNetwork]}&from=${tokenAddress}&tab=swap&to=${NETWORK_SOURCE_TOKEN[lowerPriceNetwork][1]}`,
                            "_blank"
                        )
                        return
                    }

                    if (shiftKey && !metaKey) {
                        toast.info(`${tokenName}: manually pinned`, {autoClose: 2000})
                        SocketClient.emitEvent(MODULE_EVENTS.PIN_TOKEN, {
                            tokenName, pinData: {
                                networkFrom: biggerPriceNetwork ?? NETWORK.ETHEREUM,
                                networkTo: lowerPriceNetwork ?? NETWORK.ETHEREUM,
                                pinnedAt: Date.now()
                            }
                        })
                        return;
                    }

                    if (trackingResult === undefined) return
                    if (biggerPriceNetwork === undefined || lowerPriceNetwork === undefined) return

                    if (metaKey) {
                        toast.info(`${tokenName}: manual trade requested`, {autoClose: 2000})
                        SocketClient.emitEvent(MODULE_EVENTS.FORCE_TRIGGER_TX, trackingResult)
                    }
                }}
            >
                {NetworkStack}
                {reverseResult?.returnAmount !== undefined && dexPosition === "left" && DexLeftStack}
                {/* <span style={{ width: '100%', maxWidth: '100%' }}> */}
                {displayValue}
                {/* </span> */}
            </Button>
            <Badge bg="light" text="dark" className="noBgHov noBorder" style={{fontSize: "0.6em"}}>
                {reverseResult?.returnAmount !== undefined && dexPosition === "bottom" && DexBottomStack}
                {reverseResult?.returnAmount !== undefined && displayRoutes === "true" && RoutesStack}
                {reverseResult?.returnAmount !== undefined && displayRoutes === "bn" && BNStack}
            </Badge>
        </Stack>
    )
})

/**
 *
 */
type CommentsCellProps = {
    tokenName: string
    networkA?: NETWORK
    networkB?: NETWORK
    tokenData: DBToken
    tokenComments: DBToken["comments"]
}
const CommentsCell = memo(function CommentsCell({
                                                    tokenName,
                                                    networkA,
                                                    networkB,
                                                    tokenData,
                                                    tokenComments,
                                                }: CommentsCellProps) {
    // Display network pair comments
    if (networkA && networkB) {
        const curValue = tokenData.networkPairComments?.[`${networkA}_${networkB}`]
        // If no network pair comments - show tinted regular comments
        if (curValue === undefined) {
            return (
                <div className="d-grid" style={{height: "100%"}}>
                    <Button
                        variant="light"
                        className="noBgHov noBorder text-truncate"
                        style={{color: "rgba(0,0,0,0.3)"}}
                        onClick={() => {
                            updateTokenNetworkPairComments(
                                tokenName,
                                networkA,
                                networkB,
                                curValue || "",
                                tokenData.networkPairComments
                            )
                        }}
                    >
                        {tokenComments || "‎ "}
                    </Button>
                </div>
            )
        }
        // Show network pair comment
        return (
            <div className="d-grid" style={{height: "100%"}}>
                <Button
                    variant="light"
                    className="noBgHov noBorder text-truncate"
                    onClick={() => {
                        updateTokenNetworkPairComments(
                            tokenName,
                            networkA,
                            networkB,
                            curValue || "",
                            tokenData.networkPairComments
                        )
                    }}
                >
                    {curValue || "‎ "}
                </Button>
            </div>
        )
    }
    // Show token comments
    return (
        <div className="d-grid" style={{height: "100%"}}>
            <Button
                variant="light"
                className="noBgHov noBorder text-truncate"
                onClick={() => updateTokenComments(tokenName, tokenComments)}
            >
                {tokenComments || "‎ "}
            </Button>
        </div>
    )
})

/**
 *
 */
type ForceSellCellProps = {
    tokenName: string
    isPinned: boolean
    tokenNetworkPairPinned?: AppState["brainState"]["pinnedTokens"]["any"]
}
const ForceSellCell = memo(function ForceSellCell({
                                                      tokenName,
                                                      isPinned,
                                                      tokenNetworkPairPinned,
                                                  }: ForceSellCellProps) {
    if (!isPinned) return <></>
    if (tokenNetworkPairPinned === undefined) return <></>

    // FromNetwork_ToNetwork_TokenName
    const forceSellKey = `${tokenNetworkPairPinned.networkFrom}_${tokenNetworkPairPinned.networkTo}_${tokenName}`

    return (
        <div className="d-grid" style={{height: "100%"}}>
            <Button
                size="sm"
                variant="light"
                onClick={() => {
                    sendUpdateEvent(MODULE_EVENTS.FORCE_SELL, forceSellKey)
                }}
            >
                {"F"}
            </Button>
        </div>
    )
})

/**
 *
 */
type PinnedCellProps = {
    tokenName: string
    isPinned: boolean
    tokenNetworkPairPinned?: AppState["brainState"]["pinnedTokens"]["any"]
    isPinnedNoTrade: boolean
    tokenNetworkPairPinnedNoTrade?: AppState["brainState"]["pinnedTokensNoTrade"]["any"]
}
const PinnedCell = memo(function PinnedCell({
                                                tokenName,
                                                isPinned,
                                                tokenNetworkPairPinned,
                                                isPinnedNoTrade,
                                                tokenNetworkPairPinnedNoTrade,
                                            }: PinnedCellProps) {
    if (!isPinned && !isPinnedNoTrade) return <></>
    if (tokenNetworkPairPinned === undefined && tokenNetworkPairPinnedNoTrade === undefined) return <></>

    return (
        <div className="d-grid" style={{height: "100%"}}>
            <Button
                size="sm"
                variant="light"
                onClick={() => {
                    if (tokenNetworkPairPinned) {
                        sendUpdateEvent(MODULE_EVENTS.UNPIN_TOKEN, {tokenName})
                        sendUpdateEvent(MODULE_EVENTS.DELETE_TOKEN_AUTOSELL, {
                            tokenName,
                            networkBuy: tokenNetworkPairPinned.networkFrom,
                            networkSell: tokenNetworkPairPinned.networkTo,
                        })
                    }
                    if (tokenNetworkPairPinnedNoTrade) {
                        sendUpdateEvent(MODULE_EVENTS.UNPIN_TOKEN_NO_TRADE, {tokenName})
                    }
                }}
            >
                {"❌"}
            </Button>
        </div>
    )
})
