/**
 *
 */

type LoggerInitData = {
	projectName: string
	logToConsole?: boolean
	logToLoki?: boolean
}

class Logger {
	private _projectName!: string
	private _logToConsole!: boolean
	private _logToLoki!: boolean

	private _lokiUrl?: string

	public init(loggerInitData: LoggerInitData) {
		this._projectName = loggerInitData.projectName
		this._logToConsole = loggerInitData.logToConsole || true
		this._logToLoki = loggerInitData.logToLoki || true

		this._lokiUrl = process.env.LOKI_URL
		if (this._logToLoki && !this._lokiUrl) {
			this._logToLoki = false
		}

		return this
	}

	public bindModule(moduleName: string) {
		return {
			log: this.log.bind(this, moduleName),
		}
	}

	/**
	 *
	 */
	public log(moduleName: string, ...data: any) {
		if (this._logToConsole) {
			this.logToConsole(moduleName, ...data)
		}

		if (this._logToLoki) {
			this.logToLoki(moduleName, data)
		}
	}

	/**
	 *
	 */
	private logToConsole(moduleName: string, ...data: any) {
		console.log(`[${this._projectName}] [${moduleName}]`, ...data)
	}

	/**
	 *
	 */
	private async logToLoki(moduleName: string, ...data: any) {
		//
	}
}

export default new Logger()
