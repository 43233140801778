import Apeswap from "./Apeswap.png"
import Pancakeswap from "./Pancakeswap.png"
import Shibaswap from "./Shibaswap.png"
import Sushiswap from "./Sushiswap.png"
import Uniswap from "./Uniswap.png"

import Biswap from "./Biswap.png"
import TraderJoe from "./TraderJoe.png"

import Camelot from "./Camelot.png"

import Quickswap from "./Quickswap.png"

import Bakeryswap from "./Bakeryswap.png"
import Cafeswap from "./Cafeswap.png"

import Default from "./Default.png"

const DEX_IMAGES: {
	[dexName: string]: string
	Default: string
} = {
	// Ethereum
	Apeswap,
	Pancakeswap,
	Shibaswap,
	Sushiswap,
	Uniswap,

	// Binance
	Biswap,
	TraderJoe,

	// Arbitrum
	Camelot,

	// Polygon
	Quickswap,

	// Old
	Bakeryswap,
	Cafeswap,

	Default: Default,
}

export function getDexImage(dexName: string) {
	return DEX_IMAGES[dexName] || DEX_IMAGES.Default
}
