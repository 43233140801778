import { WagmiConfig, configureChains, createConfig } from "wagmi"
import * as _chains from "wagmi/chains"
import { publicProvider } from "wagmi/providers/public"
import { InjectedConnector } from "wagmi/connectors/injected"
import { ReactNode, memo } from "react"

const { chains, publicClient } = configureChains(Object.values(_chains), [publicProvider()])

const config = createConfig({
	autoConnect: true,
	connectors: [new InjectedConnector({ chains })],
	publicClient,
})

export default memo(function WagmiWrapper({ children }: { children: ReactNode }) {
	return <WagmiConfig config={config}>{children}</WagmiConfig>
})
