import { memo } from "react"
import { Col, Container, Row, Button } from "react-bootstrap"

import Header_C, {
	HeaderBlockNumSection_C,
	HeaderConnectedServicesSection_C,
	HeaderSection,
	HeaderStartStopSection_C,
} from "../../../components/Header"
import Content, { ContentSection, ContentSpacer } from "../../../components/Content"
import Sidebar, { SidebarRootNavLinks, SidebarSection } from "../../../components/Sidebar"

import { SidebarSettingsNavLinks } from ".."
import { Competitors } from "../../../common/types/monitoringTypes"
import SettingsCompetitorsTable from "./CompetitorsTable"
import { addCompetitor } from "./actions"

/**
 * Competitors settings page
 *  Route '/settings/competitors'
 */
type SettingsCompetitorsProps = {
	competitors: Competitors
}

export default memo(function SettingsCompetitors({ competitors }: SettingsCompetitorsProps) {
	return (
		<>
			<Header_C>
				<HeaderSection>
					<HeaderStartStopSection_C />
				</HeaderSection>
				<HeaderSection justifyContent="center">
					<HeaderConnectedServicesSection_C />
				</HeaderSection>
				<HeaderSection justifyContent="end">
					<HeaderBlockNumSection_C />
				</HeaderSection>
			</Header_C>

			<Container fluid className="main">
				<Row>
					<Col className="sidebarContainer">
						<Sidebar>
							<SidebarRootNavLinks />
							<SidebarSettingsNavLinks />

							<SidebarSection renderP gap={1}>
								<SideButtons competitors={competitors} />
							</SidebarSection>
						</Sidebar>
					</Col>
					<Col className="contentContainer">
						<Content>
							<ContentSection>
								<SettingsCompetitorsTable competitors={competitors} />
							</ContentSection>
							<ContentSection>
								<ContentSpacer height={100} />
							</ContentSection>
						</Content>
					</Col>
				</Row>
			</Container>
		</>
	)
})

/**
 *
 */
type SideButtonsProps = {
	competitors: Competitors
}
const SideButtons = memo(function SideButtons({ competitors }: SideButtonsProps) {
	return (
		<Button size="sm" variant="success" onClick={() => addCompetitor(competitors)}>
			Add new competitor
		</Button>
	)
})
