import {BRIDGE, DEX, NETWORK} from "../defaults"
import {TokenShortArrayData} from "./tokenTypes"
import {Route} from "./workerTypes"

export enum TxType {
    // bundle types
    Bundle = "Bundle",
    BundleSell = "BundleSell",

    // separate types
    ApproveSwap = "ApproveSwap",
    ApproveSell = "ApproveSell",
    Swap = "Swap",
    Sell = "Sell",
    ApproveBridge = "ApproveBridge",
    Bridge = "Bridge",
    Claim = "Claim",
    Transfer = "Transfer",

    // Window Txs
    Window = "Window",
}

export enum AggregatorType {
    Inch = "inch",
    Paraswap = "paraswap",
    ZeroX = "zerox",
    Jupiter = "jupiter",
}

export type DBTX = {
    // after tx creation
    tradeId: string
    iterId: string
    txType: TxType

    bribeWei?: number
    bribeUSD?: number
    bundleIndex: number
    sendMethod: string

    networkBuy: NETWORK
    networkSell: NETWORK

    sender: string
    tokenName: string
    tokenAddress: string
    txHash: string
    txVersion: 0 | 2
    provider: string
    nonce: number
    contractAddress?: string
    inputData?: string
    value?: string

    dexName?: string
    bridgeName?: string
    bridgeAmount?: string
    bridgeApproveTarget?: string

    blockStart: number
    blockStartTime?: number
    blockSubmitted: number
    blockSubmittedTime?: number
    blockTarget: number
    blockTargetTime?: number
    blockConfirmed?: number
    blockConfirmedTime?: number
    blockPos?: number

    timeSent: number
    timeCreated: number
    timeStart?: number

    srcTokenAmount?: string
    dstTokenAmount?: string
    expectedProfit?: string

    gasPrice?: string
    gasLimit?: string
    maxFeePerGas?: string
    maxPriorityFeePerGas?: string

    success?: boolean
    errMessage?: string
    replaced?: boolean
    isManual?: boolean
    sendBack?: boolean

    // after tx confirmation
    timeConfirmed?: number

    fee?: string
    effectiveGasPrice?: string
    gasUsed?: string

    nativePrice?: number
    ethPrice?: number
}

export type TradeTxs = { [txHash: string]: DBTX }

export type Trade = {
    id: string
    startTime: number // time when trade started
    completionTime: number // time when trade completed (not including sell)
    txs: TradeTxs
    sellFailed?: boolean
}

export type Trades = {
    [tradeId: string]: Trade
}

export type EncodedTx = {
    type: number
    from: string
    to: string
    value: string
    data: string

    nonce?: number
    gasLimit?: bigint
    maxFeePerGas?: bigint
    gasPrice?: bigint
    maxPriorityFeePerGas?: bigint
    chainId?: number
}

export type TxFee = {
    effectiveGasPrice?: string
    gasUsed?: string
    fee: string
}

export type SellEvent = {
    networkBuy: NETWORK
    networkSell: NETWORK
    tokenName: string
}

export enum SELL_STATUS {
    CREATED = "created",
    PENDING = "pending",
    COMPLETED = "completed",
    FAILED = "failed",
    STALE = "stale",
    SKIP = "skip",
    SENT_BACK = "sent-back",
}

export type Sell = {
    tradeId: string
    iterId: string
    aggregatorType: AggregatorType
    profit: string
    blockNumber: number
    timeCreated: number

    bridge: BRIDGE
    bridgeApproveTarget?: string
    bridgeTxHash: string

    networkBuy: NETWORK
    networkSell: NETWORK

    amount: string
    amountShifted: string

    token: TokenInfo

    balance: string
    route?: Route

    sendBack?: boolean
    isClaimable: boolean
    skipSell?: boolean
    claimStatus: SELL_STATUS
    sellStatus: SELL_STATUS

    expectedAmountOut?: number
    aggregatorAmountOut?: number
    minAmountOut?: number
}

export function getSellKey(data: SellEvent): string {
    return `${data.networkBuy}_${data.networkSell}_${data.tokenName}`
}

export type BuildTransfer = {
    network: NETWORK
    amount: string
    tokenName: string
}

export type BuildDex = {
    txType: TxType.Swap | TxType.Sell
    profit: string,
    blockNumber: number

    networkBuy: NETWORK
    networkSell: NETWORK

    amountIn: string
    amountInShifted: string
    amountOut: string
    amountOutShifted: string

    swapTx?: WorkerSwapTx
    bundle?: boolean
    aggregatorType?: AggregatorType
    dexName?: DEX
    tokenName?: string
    fees?: number[]
    path?: TokenShortArrayData[]
}

export type BuildApprove = {
    tradeId?: string
    txType: TxType.ApproveSwap | TxType.ApproveSell | TxType.ApproveBridge
    targetAddr: string
    tokenName: string
    tokenAddr: string
    network: NETWORK
}

export type BuildBridge = {
    bridgeName: BRIDGE
    skipApprovalCheck: boolean
    blockNumber: number

    networkBuy: NETWORK
    networkSell: NETWORK

    amountOut: string
    amountOutShifted: string

    token: TokenInfo

    bundleContractAddress?: string
}

export type BridgeData = {
    bridgeName: BRIDGE
    addresses: { [network in NETWORK]?: string }
    tokens?: { [tokenName: string]: { [network in NETWORK]?: string } }
}

export type DBBridgeData = { [name in BRIDGE]: BridgeData }

export type WorkerSwapTx = {
    from: string
    to: string
    data: string
    value: string
    gas: number
    gasPrice: string
}

export type TokenInfo = {
    tokenName: string
    tokenDecim: number
    tokenBuyAddr: string
    tokenSellAddr: string
    expensiveBribe?: boolean
}

export type Allowance = {
    [tokenName: string]: AllowanceToken
}

export type AllowanceToken = {
    tokenName: string
    data: { [network in NETWORK]: AllowanceNetwork }
}

export type AllowanceNetwork = {
    address: string
    targets: AllowanceTargets
}

export type AllowanceTargets = {
    [address: string]: boolean
}

export type LockedNonces = { [network in NETWORK]?: number | null }
