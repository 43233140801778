import React, { memo, useCallback, useEffect, useRef } from "react"

import { Badge, Button, Stack, Form } from "react-bootstrap"

import VirtualTable, { VirtualTableRefProps } from "../../../components/VirtualTable"
import { AWSContainerCpuMemory, AWSContainerScheme, AWSGroupScheme } from "../../../common/types"
import {
	createAWSGroupContainer,
	deleteAWSGroup,
	editAWSGroupContainer,
	updateAWSGroupCpuOrMemory,
	updateAWSGroupName,
	updateAWSGroupReplicas,
} from "./actions"

const columns = [
	{ name: "Group name", key: "group-name", minWidth: 200, width: "-webkit-fill-available" },
	// { name: "Group #Replicas", key: "group-replicas", minWidth: 100, width: "-webkit-fill-available" },
	{ name: "Containers: #Replicas", key: "container", minWidth: 400, width: "-webkit-fill-available" },
	{ name: "Cont min cpu", key: "group-min-cpu", minWidth: 100, width: "-webkit-fill-available" },
	{ name: "Cont max cpu", key: "group-max-cpu", minWidth: 100, width: "-webkit-fill-available" },
	{ name: "Cont min memory", key: "group-min-memory", minWidth: 100, width: "-webkit-fill-available" },
	{ name: "Cont max memory", key: "group-max-memory", minWidth: 100, width: "-webkit-fill-available" },
	{ name: "x", key: "delete", minWidth: 40 },
]

/**
 *
 */
type AWSGroupsTableProps = {
	awsGroups: AWSGroupScheme[]
}
export default memo(function AWSGroupsTable({ awsGroups }: AWSGroupsTableProps) {
	const virtualTableRef = useRef<VirtualTableRefProps>(null)

	useEffect(() => {
		virtualTableRef.current?.measure()
	}, [awsGroups])

	const data = awsGroups.map((awsGroup) => {
		return [
			<GroupNameCell groupName={awsGroup.name} awsGroups={awsGroups} />,
			// <GroupReplicasCell
			// 	groupName={awsGroup.name}
			// 	groupReplicas={awsGroup.replicas}
			// 	awsGroups={awsGroups}
			// />,
			<ContainersCell
				groupName={awsGroup.name}
				containers={awsGroup.containers ?? []}
				awsGroups={awsGroups}
			/>,
			<GroupMinCpuCell groupName={awsGroup.name} minCpu={awsGroup.minCpu} awsGroups={awsGroups} />,
			<GroupMaxCpuCell groupName={awsGroup.name} maxCpu={awsGroup.maxCpu} awsGroups={awsGroups} />,
			<GroupMinMemoryCell
				groupName={awsGroup.name}
				minMemory={awsGroup.minMemory}
				awsGroups={awsGroups}
			/>,
			<GroupMaxMemoryCell
				groupName={awsGroup.name}
				maxMemory={awsGroup.maxMemory}
				awsGroups={awsGroups}
			/>,
			<DeleteCell groupName={awsGroup.name} awsGroups={awsGroups} />,
		]
	})

	const estimateRowHeight = (index: number) => ((awsGroups[index].containers || []).length + 1) * 38
	// const estimateRowHeight = (index: number) => 40

	return (
		<VirtualTable
			columns={columns}
			data={data}
			style={{ fontFamily: "monospace" }}
			estimateRowHeight={estimateRowHeight}
			ref={virtualTableRef}
		/>
	)
})

/**
 *
 */
type GroupNameCellProps = {
	groupName: string
	awsGroups: AWSGroupScheme[]
}
const GroupNameCell = memo(function GroupNameCell({ groupName, awsGroups }: GroupNameCellProps) {
	return (
		<div className="d-grid" style={{ height: "100%" }}>
			<Button
				size="sm"
				variant="light"
				className="noBgHov noBorder"
				onClick={() => updateAWSGroupName(groupName, awsGroups)}
			>
				{groupName}
			</Button>
		</div>
	)
})

/**
 *
 */
type GroupReplicasCellProps = {
	groupName: string
	groupReplicas: number
	awsGroups: AWSGroupScheme[]
}
const GroupReplicasCell = memo(function GroupReplicasCell({
	groupName,
	groupReplicas,
	awsGroups,
}: GroupReplicasCellProps) {
	return (
		<div className="d-grid" style={{ height: "100%" }}>
			<Button
				size="sm"
				variant="light"
				className="noBgHov noBorder"
				onClick={() => updateAWSGroupReplicas(groupName, groupReplicas, awsGroups)}
			>
				{groupReplicas}
			</Button>
		</div>
	)
})

/**
 *
 */
type GroupMinCpuCellProps = {
	groupName: string
	minCpu: string
	awsGroups: AWSGroupScheme[]
}
const GroupMinCpuCell = memo(function GroupMinCpuCell({ groupName, minCpu, awsGroups }: GroupMinCpuCellProps) {
	return (
		<div className="d-grid" style={{ height: "100%" }}>
			<Form.Select
				size="sm"
				value={minCpu}
				style={{ textAlign: "center" }}
				onChange={(e) => {
					const value = e.target.value
					updateAWSGroupCpuOrMemory(groupName, "minCpu", value, awsGroups)
				}}
			>
				<option key={'?'} value={'?'}>
					{'?'}
				</option>
				{Object.values(AWSContainerCpuMemory).map((value) => (
					<option key={value} value={value}>
						{value}
					</option>
				))}
			</Form.Select>
		</div>
	)
})
type GroupMaxCpuCellProps = {
	groupName: string
	maxCpu: string
	awsGroups: AWSGroupScheme[]
}
const GroupMaxCpuCell = memo(function GroupMaxCpuCell({ groupName, maxCpu, awsGroups }: GroupMaxCpuCellProps) {
	return (
		<div className="d-grid" style={{ height: "100%" }}>
			<Form.Select
				size="sm"
				value={maxCpu}
				style={{ textAlign: "center" }}
				onChange={(e) => {
					const value = e.target.value
					updateAWSGroupCpuOrMemory(groupName, "maxCpu", value, awsGroups)
				}}
			>
				<option key={'?'} value={'?'}>
					{'?'}
				</option>
				{Object.values(AWSContainerCpuMemory).map((value) => (
					<option key={value} value={value}>
						{value}
					</option>
				))}
			</Form.Select>
		</div>
	)
})

/**
 *
 */
type GroupMinMemoryCellProps = {
	groupName: string
	minMemory: string
	awsGroups: AWSGroupScheme[]
}
const GroupMinMemoryCell = memo(function GroupMinMemoryCell({
	groupName,
	minMemory,
	awsGroups,
}: GroupMinMemoryCellProps) {
	return (
		<div className="d-grid" style={{ height: "100%" }}>
			<Form.Select
				size="sm"
				value={minMemory}
				style={{ textAlign: "center" }}
				onChange={(e) => {
					const value = e.target.value
					updateAWSGroupCpuOrMemory(groupName, "minMemory", value, awsGroups)
				}}
			>
				<option key={'?'} value={'?'}>
					{'?'}
				</option>
				{Object.values(AWSContainerCpuMemory).map((value) => (
					<option key={value} value={value}>
						{value}
					</option>
				))}
			</Form.Select>
		</div>
	)
})
type GroupMaxMemoryCellProps = {
	groupName: string
	maxMemory: string
	awsGroups: AWSGroupScheme[]
}
const GroupMaxMemoryCell = memo(function GroupMaxMemoryCell({
	groupName,
	maxMemory,
	awsGroups,
}: GroupMaxMemoryCellProps) {
	return (
		<div className="d-grid" style={{ height: "100%" }}>
			<Form.Select
				size="sm"
				value={maxMemory}
				style={{ textAlign: "center" }}
				onChange={(e) => {
					const value = e.target.value
					updateAWSGroupCpuOrMemory(groupName, "maxMemory", value, awsGroups)
				}}
			>
				<option key={'?'} value={'?'}>
					{'?'}
				</option>
				{Object.values(AWSContainerCpuMemory).map((value) => (
					<option key={value} value={value}>
						{value}
					</option>
				))}
			</Form.Select>
		</div>
	)
})

/**
 *
 */
type ContainersCellProps = {
	groupName: string
	containers: AWSContainerScheme[]
	awsGroups: AWSGroupScheme[]
}
const ContainersCell = memo(function ContainersCell({
	groupName,
	containers,
	awsGroups,
}: ContainersCellProps) {
	return (
		<Stack direction="vertical" gap={2}>
			{containers.map((container) => {
				return (
					<Stack
						direction="horizontal"
						className="justify-content-center"
						gap={2}
						key={container.name}
					>
						<div className="d-grid" style={{ width: "100%" }}>
							<Button
								size="sm"
								variant="light"
								className="noBgHov noBorder"
								onClick={() => editAWSGroupContainer(groupName, container.name, awsGroups)}
							>
								{container.name}: {container.replicas}
							</Button>
						</div>
					</Stack>
				)
			})}
			<Button
				size="sm"
				variant="light"
				className="noBgHov noBorder"
				onClick={() => createAWSGroupContainer(groupName, awsGroups)}
			>
				{"+"}
			</Button>
		</Stack>
	)
})

/**
 *
 */
type DeleteCellProps = {
	groupName: string
	awsGroups: AWSGroupScheme[]
}
const DeleteCell = memo(function DeleteCell({ groupName, awsGroups }: DeleteCellProps) {
	return (
		<div className="d-grid" style={{ height: "100%" }}>
			<Button size="sm" variant="light" onClick={() => deleteAWSGroup(groupName, awsGroups)}>
				{"x"}
			</Button>
		</div>
	)
})
