import { memo, useDeferredValue, useState } from "react"
import { Row, Col, Container } from "react-bootstrap"

import Header_C, {
	HeaderBlockNumSection_C,
	HeaderConnectedServicesSection_C,
	HeaderSection,
	HeaderStartStopSection_C,
} from "../../../components/Header"
import Content, { ContentSection, ContentSpacer } from "../../../components/Content"
import Sidebar, { SidebarRootNavLinks, SidebarSection } from "../../../components/Sidebar"
import TransactionsTable from "../table/TransactionsTable"
import { DBTokens, Trades } from "../../../common/types"
import {
	SideButtons,
	SidebarClearFilters,
	SidebarPropertyFilter,
	SidebarTextFilter,
	SidebarTransactionsNavLinks,
	TransactionsFilterData,
	defaultFilterData,
} from "../index"

/**
 * Latest Transactions page
 *  Route '/tx/latest'
 */
type LatestTransactionsProps = {
	tokens: DBTokens
	trades: Trades
}
export default memo(function LatestTransactions({ tokens, trades }: LatestTransactionsProps) {
	const [textFilter, setTextFilter] = useState("")
	const [filterData, setFilterData] = useState<TransactionsFilterData>(defaultFilterData)

	const deferredTextFilter = useDeferredValue(textFilter)

	return (
		<>
			<Header_C>
				<HeaderSection>
					<HeaderStartStopSection_C />
				</HeaderSection>
				<HeaderSection justifyContent="center">
					<HeaderConnectedServicesSection_C />
				</HeaderSection>
				<HeaderSection justifyContent="end">
					<HeaderBlockNumSection_C />
				</HeaderSection>
			</Header_C>

			<Container fluid className="main">
				<Row>
					<Col className="sidebarContainer">
						<Sidebar>
							<SidebarRootNavLinks />
							<SidebarTransactionsNavLinks />

							<SidebarSection name="Filters" gap={2}>
								<SidebarTextFilter {...{ textFilter, setTextFilter }} />
								<SidebarPropertyFilter {...{ filterData, setFilterData }} />
								<SidebarClearFilters
									{...{
										textFilter,
										filterData,
										setTextFilter,
										setFilterData,
									}}
								/>
							</SidebarSection>

							<SidebarSection renderP gap={1}>
								<SideButtons {...{ allTokens: tokens }} />
							</SidebarSection>
						</Sidebar>
					</Col>
					<Col className="contentContainer">
						<Content>
							<ContentSection>
								<TransactionsTable
									textFilter={deferredTextFilter}
									filterData={filterData}
									tokens={tokens}
									trades={trades}
								/>
							</ContentSection>
							<ContentSection>
								<ContentSpacer height={100} />
							</ContentSection>
						</Content>
					</Col>
				</Row>
			</Container>
		</>
	)
})
