import {BRAIN_EVENTS, BRAIN_EVENTS_PARAMS} from "./brainEvents"
import {EVENTER_EVENTS, EVENTER_EVENTS_PARAMS} from "./eventerEvents"
import {CRONER_EVENTS, CRONER_EVENTS_PARAMS} from "./cronerEvents"
import {GLOBAL_EVENTS, GLOBAL_EVENTS_PARAMS} from "./globalEvents"
import {GO_NODER_EVENT_PARAMS, GO_NODER_EVENTS} from "./goNoderEvents"
import {INTERFACE_EVENTS, INTERFACE_EVENTS_PARAMS} from "./interfaceEvents"
import {NODER_EVENTS, NODER_EVENTS_PARAMS} from "./noderEvents"
import {TRANSACTIONS_EVENTS, TRANSACTIONS_EVENTS_PARAMS} from "./transactionsEvents"
import {WORKER_EVENTS, WORKER_EVENTS_PARAMS} from "./workerEvents"
import {MONITORING_EVENTS, MONITORING_EVENTS_PARAMS} from "./monitoringEvents"

export * from "./brainEvents"
export * from "./eventerEvents"
export * from "./cronerEvents"
export * from "./globalEvents"
export * from "./goNoderEvents"
export * from "./interfaceEvents"
export * from "./noderEvents"
export * from "./transactionsEvents"
export * from "./workerEvents"

/**
 * Merge event enums
 */
export const MODULE_EVENTS = {
    ...BRAIN_EVENTS,
    ...EVENTER_EVENTS,
    ...CRONER_EVENTS,
    ...GLOBAL_EVENTS,
    ...GO_NODER_EVENTS,
    ...INTERFACE_EVENTS,
    ...NODER_EVENTS,
    ...TRANSACTIONS_EVENTS,
    ...WORKER_EVENTS,
    ...MONITORING_EVENTS,
}

/**
 * Merge event params types
 */
export type MODULE_EVENTS_PARAMS =
    EVENTER_EVENTS_PARAMS &
    CRONER_EVENTS_PARAMS &
    BRAIN_EVENTS_PARAMS &
    GLOBAL_EVENTS_PARAMS &
    GO_NODER_EVENT_PARAMS &
    INTERFACE_EVENTS_PARAMS &
    NODER_EVENTS_PARAMS &
    TRANSACTIONS_EVENTS_PARAMS &
    WORKER_EVENTS_PARAMS &
    MONITORING_EVENTS_PARAMS

/**
 * It is needed. Please keep it like it is
 */
export type MODULE_EVENTS = keyof MODULE_EVENTS_PARAMS

/**
 * Events used in connection establishment + secured event
 */
export const CONNECTION_SPECIFIC_MODULE_EVENTS = [
    MODULE_EVENTS.CLIENT_KEY_EXCHANGE,
    MODULE_EVENTS.SERVER_KEY_EXCHANGE,
    MODULE_EVENTS.CLIENT_AUTH_REQUEST,
    MODULE_EVENTS.SERVER_AUTH_SUCCESS,
    MODULE_EVENTS.SERVER_AUTH_FAILURE,
    MODULE_EVENTS.SECURED_EVENT,
]

/**
 *
 */
export type MODULE_EVENTS_EXCLUDE_SECURE = Exclude<MODULE_EVENTS, typeof MODULE_EVENTS.SECURED_EVENT>
