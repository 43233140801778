import { memo, useDeferredValue, useEffect, useState } from "react"

import { Row, Col, Container, Button } from "react-bootstrap"

import Header_C, {
	HeaderBlockNumSection_C,
	HeaderConnectedServicesSection_C,
	HeaderSection,
	HeaderStartStopSection_C,
} from "../../../components/Header"
import Content, { ContentSection, ContentSpacer } from "../../../components/Content"
import Sidebar, { SidebarRootNavLinks, SidebarSection } from "../../../components/Sidebar"
import TransactionsTable from "../table/TransactionsTable"
import { Trades, DBTokens } from "../../../common/types"
import { fetchArchivedFile, fetchArchivedFiles } from "../actions"
import {
	SideButtons,
	SidebarClearFilters,
	SidebarPropertyFilter,
	SidebarTextFilter,
	SidebarTransactionsNavLinks,
	TransactionsFilterData,
	defaultFilterData,
} from "../index"

/**
 * Archived Transactions page
 *  Route '/tx/arch'
 */
type ArchivedTransactionsProps = {
	tokens: DBTokens
	currentArchiveFileName: string
	fileList: string[]
	data: { [key: string]: string }
}
export default memo(function ArchivedTransactions({
	tokens,
	currentArchiveFileName,
	fileList,
	data,
}: ArchivedTransactionsProps) {
	const [textFilter, setTextFilter] = useState("")
	const [filterData, setFilterData] = useState<TransactionsFilterData>(defaultFilterData)

	const deferredTextFilter = useDeferredValue(textFilter)

	useEffect(() => {
		console.log("calling useEffect")
		setTimeout(fetchArchivedFiles, 2000)
	}, [])

	const trades =
		!!data && !!data[currentArchiveFileName] ? (JSON.parse(data[currentArchiveFileName]) as Trades) : {}

	return (
		<>
			<Header_C>
				<HeaderSection>
					<HeaderStartStopSection_C />
				</HeaderSection>
				<HeaderSection justifyContent="center">
					<HeaderConnectedServicesSection_C />
				</HeaderSection>
				<HeaderSection justifyContent="end">
					<HeaderBlockNumSection_C />
				</HeaderSection>
			</Header_C>

			<Container fluid className="main">
				<Row>
					<Col className="sidebarContainer">
						<Sidebar>
							<SidebarRootNavLinks />
							<SidebarTransactionsNavLinks />

							<SidebarSection name="Filters" gap={2}>
								<SidebarTextFilter {...{ textFilter, setTextFilter }} />
								<SidebarPropertyFilter {...{ filterData, setFilterData }} />
								<SidebarClearFilters
									{...{
										textFilter,
										filterData,
										setTextFilter,
										setFilterData,
									}}
								/>
							</SidebarSection>

							<SidebarSection renderP gap={1}>
								<SideButtons {...{ allTokens: tokens }} />
							</SidebarSection>
						</Sidebar>
					</Col>
					<Col className="contentContainer">
						<Content>
							<ContentSection>
								<ArchivedTransactionsList
									data={fileList}
									currentFileName={currentArchiveFileName}
								/>
							</ContentSection>
							<ContentSection>
								<TransactionsTable
									textFilter={deferredTextFilter}
									filterData={filterData}
									tokens={tokens}
									trades={trades}
								/>
							</ContentSection>
							<ContentSection>
								<ContentSpacer height={100} />
							</ContentSection>
						</Content>
					</Col>
				</Row>
			</Container>
		</>
	)
})

type ArchivedTransactionsListProps = {
	data: string[]
	currentFileName: string
}
const ArchivedTransactionsList = memo(function ArchivedTransactionsList({
	data,
	currentFileName,
}: ArchivedTransactionsListProps) {
	const groupedByYear: { [key: string]: string[] } = data.reduce((acc, fileName) => {
		const year = fileName.split("_")[1].split(".")[0]
		acc[year] = acc[year] || []
		acc[year].push(fileName)
		return acc
	}, {} as any)

	return (
		<div>
			{Object.entries(groupedByYear).map(([year, files]) => (
				<div key={year} style={{ display: "flex", flexDirection: "column", marginBottom: "20px" }}>
					<h4>{year}</h4>
					<div style={{ display: "flex" }}>
						{files.map((fileName) => (
							<Button
								key={fileName}
								size="sm"
								variant={currentFileName === fileName ? "dark" : "light"}
								style={{ fontSize: "0.8em", margin: "5px" }}
								onClick={() => fetchArchivedFile(fileName)}
							>
								{fileName.replace(".json", "")}
							</Button>
						))}
					</div>
				</div>
			))}
		</div>
	)
})
