import React, {forwardRef, memo, useImperativeHandle, useLayoutEffect, useRef} from "react"
import {useVirtualizer, useWindowVirtualizer} from "@tanstack/react-virtual"
import {Button} from "react-bootstrap"

/**
 *
 */
type VitrualTableColumn = {
    name: string
    key: string
    minWidth: number
    tooltip?: string
    width?: number | string
}

export type HeaderRow = {
    title: string
    profit: number,
    fee: number,
    time: number,
    color: string
    action?: {
        name: string
        handler: () => void
    }
}

export type TableRow = HeaderRow | JSX.Element[]

type VitrualTableProps = {
    columns: VitrualTableColumn[]
    data: TableRow[]
    style?: React.CSSProperties
    estimateRowHeight: (index: number) => number
}

export type VirtualTableRefProps = {
    measure: () => void
}

export default memo(
    forwardRef(function VirtualTable({columns, data, style, estimateRowHeight}: VitrualTableProps, ref) {
        const parentRef = useRef<HTMLDivElement | null>(null)
        const parentOffsetRef = useRef(0)

        useLayoutEffect(() => {
            parentOffsetRef.current = parentRef.current?.offsetTop ?? 0
        }, [])

        const getColumnWidth = (index: number) => columns[index].width
        const getColumnMinWidth = (index: number) => columns[index].minWidth
        const getColumnTooltip = (index: number) => columns[index].tooltip

        const virtualizer = useWindowVirtualizer({
            count: data.length,
            estimateSize: estimateRowHeight,
            overscan: 5,
            scrollMargin: parentOffsetRef.current,
        })

        const columnVirtualizer = useVirtualizer({
            horizontal: true,
            count: columns.length,
            getScrollElement: () => parentRef.current,
            estimateSize: getColumnMinWidth,
            overscan: 5,
        })

        useImperativeHandle(
            ref,
            () => {
                const props: VirtualTableRefProps = {
                    measure: () => {
                        virtualizer.measure()
                    },
                }
                return props
            },
            []
        )

        const columnItems = columnVirtualizer.getVirtualItems()
        const [before, after] =
            columnItems.length > 0
                ? [
                    columnItems[0].start,
                    columnVirtualizer.getTotalSize() - columnItems[columnItems.length - 1].end,
                ]
                : [0, 0]

        return (
            <div style={{...style}}>
                <div
                    className="stickyHeader"
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        verticalAlign: "center",
                        textAlign: "center",
                    }}
                >
                    {columnItems.map((column) => {
                        const width = getColumnWidth(column.index)
                        const minWidth = getColumnMinWidth(column.index)
                        const tooltip = getColumnTooltip(column.index)

                        return (
                            <div
                                key={columns[column.index].key}
                                title={tooltip ?? ""}
                                style={{
                                    width: width || minWidth,
                                    minWidth: minWidth,
                                    borderRight: column.index < columns.length - 1 ? "1px solid #e3e3e3" : "",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <span>{columns[column.index].name}</span>
                            </div>
                        )
                    })}
                </div>
                <div ref={parentRef} style={{overflowY: "auto"}}>
                    <div
                        style={{
                            height: virtualizer.getTotalSize(),
                            position: "relative",
                        }}
                    >
                        {virtualizer.getVirtualItems().map((row) => {
                            if (!row) return <></>

                            if (typeof data[row.index] === "object" && "title" in (data[row.index] as any)) {
                                const rowData = data[row.index] as HeaderRow

                                return (
                                    <div
                                        key={row.key}
                                        data-index={row.index}
                                        style={{
                                            left: 0,
                                            top: 0,
                                            color: rowData.color ?? "inherit",
                                            width: "100%",
                                            minHeight: row.size,
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "end",
                                            paddingBottom: 8,
                                            paddingLeft: 8,
                                            fontWeight: 600,
                                            position: "absolute",
                                            borderBottom: "1px solid var(--bs-gray-200)",
                                            transform: `translateY(${
                                                row.start - virtualizer.options.scrollMargin
                                            }px)`,
                                        }}
                                    >
                                        {rowData.title}
                                        {!!rowData.action && (
                                            <div className="d-grid" style={{height: 50, width: 98}}>
                                                <Button
                                                    size="sm"
                                                    variant="light"
                                                    style={{fontSize: "0.8em", cursor: "pointer"}}
                                                    className="text-truncate"
                                                    onClick={rowData.action.handler}
                                                >
                                                    {rowData.action.name}
                                                </Button>
                                            </div>
                                        )}
                                    </div>
                                )
                            }

                            const rowData = data[row.index] as JSX.Element[]
                            return (
                                <div
                                    key={row.key}
                                    data-index={row.index}
                                    // ref={virtualizer.measureElement}
                                    // ref={el => {
                                    //     // virtualizer.measureElement(el)
                                    //     // columnVirtualizer.measureElement(el)
                                    // }}
                                    style={{
                                        display: "flex",
                                        left: 0,
                                        top: 0,
                                        width: "100%",
                                        position: "absolute",
                                        transform: `translateY(${
                                            row.start - virtualizer.options.scrollMargin
                                        }px)`,
                                    }}
                                >
                                    <div style={{width: `${before}px`}}/>
                                    {columnItems.map((column) => {
                                        const width = getColumnWidth(column.index)
                                        const minWidth = getColumnMinWidth(column.index)
                                        return (
                                            <div
                                                key={column.key}
                                                data-index={column.index}
                                                // ref={virtualizer.measureElement}
                                                // ref={el => {
                                                //     virtualizer.measureElement(el)
                                                //     columnVirtualizer.measureElement(el)
                                                // }}
                                                style={{
                                                    minHeight: row.size,
                                                    width: width || minWidth,
                                                    minWidth: minWidth,
                                                    borderBottom: "1px solid var(--bs-gray-200)",
                                                    display: "flex",
                                                    verticalAlign: "middle",
                                                    alignItems: "center",
                                                    padding: "4px 2px",
                                                }}
                                            >
                                                <div
                                                    className="d-grid"
                                                    style={{
                                                        width: "100%",
                                                        height: "100%",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    {rowData[column.index]}
                                                </div>
                                            </div>
                                        )
                                    })}
                                    <div style={{width: `${after}px`}}/>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        )
    })
)
