import EventEmitter from "events"
import { MODULE_EVENTS, MODULE_EVENTS_PARAMS } from "./moduleEvents"

class InnerEmitter extends EventEmitter {
	/**
	 * EventEmitter override to subscribe for MODULE_EVENTS
	 */
	public override on<T extends MODULE_EVENTS>(
		eventName: T,
		listener: (data: MODULE_EVENTS_PARAMS[T]) => void
	): this {
		super.on(eventName, listener)
		return this
	}

	/**
	 * EventEmitter override to UNsubscribe from MODULE_EVENTS
	 */
	public override off<T extends MODULE_EVENTS>(
		eventName: T,
		listener: (data: MODULE_EVENTS_PARAMS[T]) => void
	): this {
		super.off(eventName, listener)
		return this
	}

	/**
	 * EventEmitter override to emit MODULE_EVENTS
	 */
	public override emit<T extends MODULE_EVENTS>(
		eventName: T,
		eventData: MODULE_EVENTS_PARAMS[T]
	): ReturnType<EventEmitter["emit"]> {
		return super.emit(eventName, eventData)
	}
}

export default new InnerEmitter()
