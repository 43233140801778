import { Competitors, UnknownTokens } from "../types/monitoringTypes"

/**
 *
 */
export enum MONITORING_EVENTS {
	MONITORING_HIDE_UNKNOWN_TOKEN = "MONITORING_HIDE_UNKNOWN_TOKEN",
	MONITORING_DELETE_UNKNOWN_TOKEN = "MONITORING_DELETE_UNKNOWN_TOKEN",
	MONITORING_ON_UPDATE_UNKNOWN_TOKEN_PROCESSED = "MONITORING_ON_UPDATE_UNKNOWN_TOKEN_PROCESSED",
	MONITORING_ADD_COMPETITOR = "MONITORING_ADD_COMPETITOR",
	MONITORING_DELETE_COMPETITOR = "MONITORING_DELETE_COMPETITOR",
	MONITORING_ON_UPDATE_COMPETITOR_PROCESSED = "MONITORING_ON_UPDATE_COMPETITOR_PROCESSED",
}

/**
 *
 */
export type MONITORING_EVENTS_PARAMS = {
	[MONITORING_EVENTS.MONITORING_HIDE_UNKNOWN_TOKEN]: string
	[MONITORING_EVENTS.MONITORING_DELETE_UNKNOWN_TOKEN]: string
	[MONITORING_EVENTS.MONITORING_ON_UPDATE_UNKNOWN_TOKEN_PROCESSED]: UnknownTokens
	[MONITORING_EVENTS.MONITORING_ADD_COMPETITOR]: string
	[MONITORING_EVENTS.MONITORING_DELETE_COMPETITOR]: string
	[MONITORING_EVENTS.MONITORING_ON_UPDATE_COMPETITOR_PROCESSED]: Competitors
}

// Verification that all events defined in ENUM have their params defined in type above
// 	If it throws error, it means that you forgot to add event params to type above
type _ = {
	[Property in MONITORING_EVENTS]: MONITORING_EVENTS_PARAMS[Property]
}
