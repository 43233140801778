import { memo, useCallback } from "react"

import { Badge, Button, Stack } from "react-bootstrap"

import VirtualTable from "../../../components/VirtualTable"

import { Competitors } from "../../../common/types/monitoringTypes"
import { NETWORK } from "../../../common"
import { deleteCompetitor } from "./actions"

const scannerUrls: any = {
	[NETWORK.ETHEREUM]: "https://etherscan.io",
	[NETWORK.BINANCE]: "https://bscscan.com",
	[NETWORK.ARBITRUM]: "https://arbiscan.io",
	[NETWORK.POLYGON]: "https://polygonscan.com",
}

const columns = [
	{ name: "Address", key: "address", minWidth: 350 },
	{ name: "Ethereum", key: "ethereum", minWidth: 250, width: "-webkit-fill-available" },
	{ name: "Binance", key: "binance", minWidth: 250, width: "-webkit-fill-available" },
	{ name: "Arbitrum", key: "arbitrum", minWidth: 250, width: "-webkit-fill-available" },
	{ name: "Polygon", key: "polygon", minWidth: 250, width: "-webkit-fill-available" },
	{ name: "x", key: "delete", minWidth: 30 },
]

/**
 *
 */
type SettingsCompetitorsTableProps = {
	competitors: Competitors
}

export default memo(function SettingsCompetitorsTable({ competitors }: SettingsCompetitorsTableProps) {
	const data = Object.values(competitors).map((address) => {
		return [
			<CompetitorsAddressCell address={address} />,
			<CompetitorsNetworkCell address={address} network={NETWORK.ETHEREUM} />,
			<CompetitorsNetworkCell address={address} network={NETWORK.BINANCE} />,
			<CompetitorsNetworkCell address={address} network={NETWORK.ARBITRUM} />,
			<CompetitorsNetworkCell address={address} network={NETWORK.POLYGON} />,
			<DeleteCell address={address} />,
		]
	})

	const estimateRowHeight = useCallback(() => 40, [])

	return (
		<Stack gap={2}>
			<VirtualTable
				columns={columns}
				data={data}
				style={{ fontFamily: "monospace" }}
				estimateRowHeight={estimateRowHeight}
			/>
		</Stack>
	)
})

/**
 *
 */
type CompetitorsAddressCellProps = {
	address: string
}
const CompetitorsAddressCell = memo(function CompetitorsAddressCell({
	address,
}: CompetitorsAddressCellProps) {
	return (
		<Badge
			bg="light"
			text="dark"
			className="noBgHov noBorder text-truncate"
			style={{ fontSize: "0.8em" }}
		>
			{address}
		</Badge>
	)
})

/**
 *
 */
type CompetitorsNetworkCellProps = {
	address: string
	network: NETWORK
}
const CompetitorsNetworkCell = memo(function CompetitorsNetworkCell({
	address,
	network,
}: CompetitorsNetworkCellProps) {
	const url = `${scannerUrls[network]}/address/${address}`
	return (
		<Button
			size="sm"
			variant="light"
			className="noBgHov noBorder text-truncate"
			onClick={(e) => {
				window.open(url)
			}}
		>
			{url || "‎ "}
		</Button>
	)
})

/**
 *
 */
type DeleteCellProps = {
	address: string
}
const DeleteCell = memo(function DeleteCell({ address }: DeleteCellProps) {
	return (
		<div className="d-grid" style={{ height: "100%" }}>
			<Button size="sm" variant="light" onClick={() => deleteCompetitor(address)}>
				{"x"}
			</Button>
		</div>
	)
})
