import Popup, { SwalStyled } from "../../../helper/Popup"
import { sendUpdateEvent } from "../../../App"

import { MODULE_EVENTS } from "../../../common/moduleEvents"
import { DBBridge, DBBridges } from "../../../common/types"

/**
 *
 */
export async function createBridge(bridges: DBBridges) {
	const result = await SwalStyled.fire({
		title: "New bridge",
		input: "text",
		inputPlaceholder: "Bridge name",
		confirmButtonText: "Create",
		allowEnterKey: true,
		inputValidator: (bridgeNameInput) => {
			if (bridgeNameInput.length === 0) return "Bridge name can not be empty"
			if (!/^[a-zA-Z0-9]+$/.test(bridgeNameInput)) return "Bridge name must be alphanumeric only"
			if (
				Object.keys(bridges)
					.map((t) => t.toLowerCase())
					.includes(bridgeNameInput.toLowerCase())
			)
				return "Bridge already exists"
			return null
		},
	})

	if (!result.isConfirmed) return
	const bridgeName = result.value

	sendUpdateEvent(MODULE_EVENTS.CREATE_BRIDGE, { bridgeName })
}

/**
 *
 */
export function updateBridgeUrl(bridgeName: string, url: DBBridge["url"]) {
	const currentValue = url

	Popup.singleInput({
		title: `Edit ${bridgeName} url`,
		value: currentValue,
		didOpen: (swal) => swal.getInput()?.setSelectionRange(0, currentValue.length, "forward"),
		validator: (v) => null,
		onNewValue: (newValue) => {
			sendUpdateEvent(MODULE_EVENTS.UPDATE_BRIDGE, { bridgeName, updates: { url: newValue } })
		},
	})
}

/**
 *
 */
export function updateBridgeInstance(bridgeName: string, instance: DBBridge["instanceName"]) {
	const currentValue = instance || ""

	Popup.singleInput({
		title: `Edit ${bridgeName} instance`,
		value: currentValue,
		didOpen: (swal) => swal.getInput()?.setSelectionRange(0, currentValue.length, "forward"),
		validator: (v) => null,
		onNewValue: (newValue) => {
			sendUpdateEvent(MODULE_EVENTS.UPDATE_BRIDGE, { bridgeName, updates: { instanceName: newValue } })
		},
	})
}

/**
 *
 */
export function deleteBridge(bridgeName: string) {
	Popup.confirm({
		title: `Delete ${bridgeName}`,
		text: `Are you sure want to delete bridge ${bridgeName}?<br/></br>All tokens' bridge field that had this bridge selected will be cleared.`,
		onConfirm: () => {
			sendUpdateEvent(MODULE_EVENTS.DELETE_BRIDGE, { bridgeName })
		},
	})
}
