import Popup, { SwalStyled } from "../../../helper/Popup"
import { sendUpdateEvent } from "../../../App"

import { MODULE_EVENTS } from "../../../common/moduleEvents"
import { Competitors } from "../../../common/types/monitoringTypes"

/**
 *
 */
export async function addCompetitor(competitors: Competitors) {
	const result = await SwalStyled.fire({
		title: "New competitor",
		input: "text",
		inputPlaceholder: "Competitor address",
		confirmButtonText: "Add",
		allowEnterKey: true,
		inputValidator: (bridgeNameInput) => {
			if (bridgeNameInput.length === 0) return "Competitor address can not be empty"
			if (
				Object.keys(competitors)
					.map((t) => t.toLowerCase())
					.includes(bridgeNameInput.toLowerCase())
			)
				return "Competitor entry already exists"
			return null
		},
	})

	if (!result.isConfirmed) return

	sendUpdateEvent(MODULE_EVENTS.MONITORING_ADD_COMPETITOR, result.value)
}

/**
 *
 */
export function deleteCompetitor(address: string) {
	Popup.confirm({
		title: `Delete ${address}`,
		text: `Are you sure want to delete competitor ${address}?`,
		onConfirm: () => {
			sendUpdateEvent(MODULE_EVENTS.MONITORING_DELETE_COMPETITOR, address)
		},
	})
}
