/**
 *
 */
export type AWSGroupScheme = {
	name: string
	replicas: number
	containers?: AWSContainerScheme[]
	minCpu: string
	maxCpu: string
	minMemory: string
	maxMemory: string
}

export type AWSContainerScheme = {
	name: string
	replicas: number
}

export type ContainerDefinition = {
	name?: string
	image?: string
	environment?: { name: string; value: string }[]
}

export type ContainerDefinitions = {
	[name: string]: ContainerDefinition
}

export type ContainerImages = {
	[name: string]: string
}

export enum AWSContainerCpuMemory {
	"X256" = "256",
	"X512" = "512",
	"X1024" = "1024",
	"X2048" = "2048",
	"X3072" = "3072",
	"X4096" = "4096",
	"X5120" = "5120",
	"X6144" = "6144",
	"X7168" = "7168",
	"X8192" = "8192",
}
