import { NETWORK } from "../defaults"

/**
 *
 */
export enum GO_NODER_EVENTS {
	NEW_BLOCK_TX_DATAS = "NEW_BLOCK_TX_DATAS",
	NEW_BLOCK_TXS_LOGS = "NEW_BLOCK_TXS_LOGS",
	BLOCK_TX_EXTRACT_ERROR = "BLOCK_TX_EXTRACT_ERROR",
	GO_NODER_SUBPROCESS_STATUS = "GO_NODER_SUBPROCESS_STATUS",
}

/**
 *
 */
export type GO_NODER_EVENT_PARAMS = {
	[GO_NODER_EVENTS.NEW_BLOCK_TX_DATAS]: {
		networkName: NETWORK
		blockNumber: string
		txDatasFiltered?:
			| {
					txHash: string
					txTo: string
					txData: string
			  }[]
			| null
		timeUsedForRetrieval: number
	}
	[GO_NODER_EVENTS.NEW_BLOCK_TXS_LOGS]: {
		networkName: NETWORK
		blockNumber: string
		txsLogs?:
			| {
					address: string
					data: string
					topics: string
			  }[]
			| null
		timeUsedForRetrieval: number
	}
	[GO_NODER_EVENTS.BLOCK_TX_EXTRACT_ERROR]: {
		networkName: NETWORK
		blockNumber: string
	}
	[GO_NODER_EVENTS.GO_NODER_SUBPROCESS_STATUS]: {
		pingSuccess: boolean
	}
}

// Verification that all events defined in ENUM have their params defined in type above
// 	If it throws error, it means that you forgot to add event params to type above
type _ = {
	[Property in GO_NODER_EVENTS]: GO_NODER_EVENT_PARAMS[Property]
}
