import { memo, useCallback, useEffect, useRef } from "react"
import unicorn from "../assets/unicorn.gif"
import React from "react"
import { useState } from "react"
import { Button } from "react-bootstrap"
import { getLogoSrc } from "./Header"
import Sound from "../helper/Sound"

/**
 *
 */
type LoaderProps = {
	projectName?: string
	buildNumber?: string
}
export default memo(function Loader({ projectName, buildNumber }: LoaderProps) {
	const [clicked, setClicked] = useState(false)
	const [removed, setRemoved] = useState(false)
	const [helperShown, setHelperShown] = useState(false)

	const removeEffects = useCallback(() => {
		setRemoved(true)
		document.body.style.overflowY = "scroll"
	}, [])

	useEffect(() => {
		setRemoved(false)
		document.body.style.overflowY = "hidden"
		return () => removeEffects()
	}, [])

	useEffect(() => {
		const _timeout = setTimeout(() => {
			setHelperShown(true)
		}, 4000)
		return () => clearTimeout(_timeout)
	}, [])

	/**
	 * Simulate click after timeout
	 */
	// useEffect(() => {
	//     const _timeout = setTimeout(() => {
	//         setClicked(true)
	//         setTimeout(() => { removeEffects() }, 300)
	//     }, 1300)

	//     return () => clearTimeout(_timeout)
	// }, [])

	if (removed) {
		return <></>
	}

	return (
		<div
			className="loaderWrapper"
			style={{ cursor: "pointer" }}
			onClick={() => {
				setClicked(true)
				Sound.play("plop")
				setTimeout(() => {
					removeEffects()
				}, 300)
			}}
		>
			<div className={"loaderOverlay " + (!clicked ? "shown" : "")}></div>
			<img className={"loaderImg " + (!clicked ? "maximize" : "")} src={getLogoSrc()} alt="" />
			<p className={"helperText " + (helperShown ? "shown" : "")} style={{ paddingBottom: "10px" }}>
				[ press anywhere to continue ]
			</p>
			<p className={"loaderText " + (!clicked ? "shown" : "")}>
				{projectName}
				<mark style={{ top: "-60px", transform: "translateX(calc(150px - 50%))" }}>
					{process.env.NODE_ENV === "development" ? "DEVELOPMENT BUILD" : ""}
				</mark>
				<span>{buildNumber ? "#" + buildNumber : ""}</span>
			</p>
		</div>
	)
})
