import React, { memo, useDeferredValue, useState } from "react"

import { Button, Col, Container, Form, Row, Stack } from "react-bootstrap"

import Header_C, {
	HeaderBlockNumSection_C,
	HeaderConnectedServicesSection_C,
	HeaderSection,
	HeaderStartStopSection_C,
} from "../../../components/Header"
import Content, { ContentSection, ContentSpacer } from "../../../components/Content"
import Sidebar, {
	SidebarFilterButton,
	SidebarFilterRow,
	SidebarRootNavLinks,
	SidebarSection,
} from "../../../components/Sidebar"

import { SidebarSettingsNavLinks } from ".."
import SettingsBridgesTable from "./BridgesTable"

import { DBBridges, DBTokens } from "../../../common/types"

import { createBridge } from "./actions"

/**
 * Filter data
 */
export type SettingsBridgesFilterData = {
	workerInstance: "true" | "false" | "none"
}
const defaultFilterData: SettingsBridgesFilterData = {
	workerInstance: "none",
}

/**
 * Sorting data
 */
export type SettingsBridgesSortingData = {
	sortBy: "bridgeName" | "numBridges" | "numBridgesEn"
	sortOrder: "asc" | "desc"
}
const defaultSortingData: SettingsBridgesSortingData = {
	sortBy: "bridgeName",
	sortOrder: "asc",
}

/**
 * Bridge settings page
 *  Route '/settings/bridges'
 */
type SettingsBridgesProps = {
	allTokens: DBTokens
	bridges: DBBridges
}
export default memo(function SettingsBridges({ allTokens, bridges }: SettingsBridgesProps) {
	const [textFilter, setTextFilter] = useState("")
	const [filterData, setFilterData] = useState<SettingsBridgesFilterData>(defaultFilterData)
	const [sortingData, setSortingData] = useState<SettingsBridgesSortingData>(defaultSortingData)

	const deferredTextFilter = useDeferredValue(textFilter)

	return (
		<>
			<Header_C>
				<HeaderSection>
					<HeaderStartStopSection_C />
				</HeaderSection>
				<HeaderSection justifyContent="center">
					<HeaderConnectedServicesSection_C />
				</HeaderSection>
				<HeaderSection justifyContent="end">
					<HeaderBlockNumSection_C />
				</HeaderSection>
			</Header_C>

			<Container fluid className="main">
				<Row>
					<Col className="sidebarContainer">
						<Sidebar>
							<SidebarRootNavLinks />
							<SidebarSettingsNavLinks />

							<SidebarSection name="Filters" gap={2}>
								<SidebarTextFilter {...{ textFilter, setTextFilter }} />
								<SidebarPropertyFilter {...{ filterData, setFilterData }} />
							</SidebarSection>

							<SidebarSection name="Sort by" gap={2}>
								<SidebarSortBy {...{ sortingData, setSortingData }} />
								<SidebarClearFilters
									{...{
										textFilter,
										filterData,
										sortingData,
										setTextFilter,
										setFilterData,
										setSortingData,
									}}
								/>
							</SidebarSection>

							<SidebarSection renderP gap={1}>
								<SideButtons bridges={bridges} />
							</SidebarSection>
						</Sidebar>
					</Col>
					<Col className="contentContainer">
						<Content>
							<ContentSection>
								<SettingsBridgesTable
									bridges={bridges}
									allTokens={allTokens}
									filterData={filterData}
									sortingData={sortingData}
									textFilter={deferredTextFilter}
								/>
							</ContentSection>
							<ContentSection>
								<ContentSpacer height={100} />
							</ContentSection>
						</Content>
					</Col>
				</Row>
			</Container>
		</>
	)
})

/**
 *
 */
type SidebarTextFilterProps = {
	textFilter: string
	setTextFilter: React.Dispatch<React.SetStateAction<string>>
}
const SidebarTextFilter = memo(function SidebarTextFilter({
	textFilter,
	setTextFilter,
}: SidebarTextFilterProps) {
	return (
		<Form.Control
			size="sm"
			type="text"
			placeholder="bridge name | bridge url"
			value={textFilter}
			onChange={(e) => setTextFilter(e.target.value)}
		/>
	)
})

/**
 *
 */
type SidebarPropertyFilterProps = {
	filterData: SettingsBridgesFilterData
	setFilterData: React.Dispatch<React.SetStateAction<SettingsBridgesFilterData>>
}
const SidebarPropertyFilter = memo(function SidebarPropertyFilter({
	filterData,
	setFilterData,
}: SidebarPropertyFilterProps) {
	const { workerInstance } = filterData

	return (
		<>
			<SidebarFilterRow text="Instance">
				<SidebarFilterButton
					text="True"
					active={workerInstance === "true"}
					onClick={() =>
						setFilterData({
							...filterData,
							workerInstance: workerInstance === "true" ? "none" : "true",
						})
					}
				/>
				<SidebarFilterButton
					text="False"
					active={workerInstance === "false"}
					onClick={() =>
						setFilterData({
							...filterData,
							workerInstance: workerInstance === "false" ? "none" : "false",
						})
					}
				/>
			</SidebarFilterRow>
		</>
	)
})

/**
 *
 */
type SidebarSortByProps = {
	sortingData: SettingsBridgesSortingData
	setSortingData: React.Dispatch<React.SetStateAction<SettingsBridgesSortingData>>
}
const SidebarSortBy = memo(function SidebarSortBy({ sortingData, setSortingData }: SidebarSortByProps) {
	return (
		<>
			<Stack direction="horizontal" gap={2} style={{ justifyContent: "space-between" }}>
				<SidebarFilterButton
					text="Bridge"
					style={{ width: "100%" }}
					active={sortingData.sortBy === "bridgeName"}
					onClick={() => setSortingData({ ...sortingData, sortBy: "bridgeName" })}
				/>
				<SidebarFilterButton
					text="NumBridges"
					style={{ width: "100%" }}
					active={sortingData.sortBy === "numBridges"}
					onClick={() => setSortingData({ ...sortingData, sortBy: "numBridges" })}
				/>
				<SidebarFilterButton
					text="NumEn"
					style={{ width: "100%" }}
					active={sortingData.sortBy === "numBridgesEn"}
					onClick={() => setSortingData({ ...sortingData, sortBy: "numBridgesEn" })}
				/>
			</Stack>
			<Stack direction="horizontal" gap={2}>
				<SidebarFilterButton
					text="Ascending"
					style={{ width: "100%" }}
					active={sortingData.sortOrder === "asc"}
					onClick={() => setSortingData({ ...sortingData, sortOrder: "asc" })}
				/>
				<SidebarFilterButton
					text="Descending"
					style={{ width: "100%" }}
					active={sortingData.sortOrder === "desc"}
					onClick={() => setSortingData({ ...sortingData, sortOrder: "desc" })}
				/>
			</Stack>
		</>
	)
})

/**
 *
 */
type SidebarClearFiltersProps = {
	textFilter: string
	filterData: SettingsBridgesFilterData
	sortingData: SettingsBridgesSortingData
	setTextFilter: React.Dispatch<React.SetStateAction<string>>
	setFilterData: React.Dispatch<React.SetStateAction<SettingsBridgesFilterData>>
	setSortingData: React.Dispatch<React.SetStateAction<SettingsBridgesSortingData>>
}
const SidebarClearFilters = memo(function SidebarClearFilters({
	textFilter,
	filterData,
	sortingData,
	setTextFilter,
	setFilterData,
	setSortingData,
}: SidebarClearFiltersProps) {
	const filtersPresent =
		false ||
		textFilter !== "" ||
		JSON.stringify(filterData) !== JSON.stringify(defaultFilterData) ||
		JSON.stringify(sortingData) !== JSON.stringify(defaultSortingData)

	if (!filtersPresent) return <></>

	return (
		<Button
			size="sm"
			variant="dark"
			onClick={() => {
				setTextFilter("")
				setFilterData(defaultFilterData)
				setSortingData(defaultSortingData)
			}}
		>
			Clear all filters
		</Button>
	)
})

/**
 *
 */
type SideButtonsProps = {
	bridges: DBBridges
}
const SideButtons = memo(function SideButtons({ bridges }: SideButtonsProps) {
	return (
		<Button size="sm" variant="success" onClick={() => createBridge(bridges)}>
			Create new bridge
		</Button>
	)
})
