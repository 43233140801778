import { NETWORK } from "../defaults"

/**
 *
 */
export enum EVENTER_EVENTS {
	EVENTER_STATS_STRING = "EVENTER_STATS_STRING",
	EVENTER_LOGS = "EVENTER_LOGS",
}

/**
 *
 */
export type EVENTER_EVENTS_PARAMS = {
	[EVENTER_EVENTS.EVENTER_STATS_STRING]: string
	[EVENTER_EVENTS.EVENTER_LOGS]: {
		network: NETWORK,
		logs: any[]
	}
}

// Verification that all events defined in ENUM have their params defined in type above
// 	If it throws error, it means that you forgot to add event params to type above
type _ = {
	[Property in EVENTER_EVENTS]: EVENTER_EVENTS_PARAMS[Property]
}
