import {NETWORK} from "../defaults"

/**
 *
 */
export enum NODER_EVENTS {
    NEW_HEADS = "NEW_HEADS",
    GAS_ESTIMATE = "GAS_ESTIMATE",
    BALANCE_UPDATE = "BALANCE_UPDATE",
    NONCE_UPDATE = "NONCE_UPDATE",
    NODEX_SUBPROCESS_STATUS = "NODEX_SUBPROCESS_STATUS",
}

/**
 *
 */
export type NODER_EVENTS_PARAMS = {
    [NODER_EVENTS.NEW_HEADS]: {
        networkName: NETWORK
        blockNumber: number
        blockTime: number
    }
    [NODER_EVENTS.GAS_ESTIMATE]: {
        networkName: NETWORK
        gasHigh: number
    }
    [NODER_EVENTS.BALANCE_UPDATE]: {
        networkName: NETWORK
        balance: { [tokenName: string]: string }
    }
    [NODER_EVENTS.NONCE_UPDATE]: {
        [networkName in NETWORK]: number
    }
    [NODER_EVENTS.NODEX_SUBPROCESS_STATUS]: {
        pingSuccess: boolean
    }
}

// Verification that all events defined in ENUM have their params defined in type above
// 	If it throws error, it means that you forgot to add event params to type above
type _ = {
    [Property in NODER_EVENTS]: NODER_EVENTS_PARAMS[Property]
}
