import { sendUpdateEvent } from "../../../App"
import { MODULE_EVENTS } from "../../../common/moduleEvents"
import { SOCKET_PATH } from "../../../common/defaults"
import { AWSContainerCpuMemory, AWSGroupScheme } from "../../../common/types"
import { SwalStyled } from "../../../helper/Popup"

/**
 *
 */
export async function createAWSGroup(awsGroups: AWSGroupScheme[]) {
	const result = await SwalStyled.fire({
		title: "New AWS Group",
		text: "Enter the name of the new AWS group",
		input: "text",
		inputPlaceholder: `group${awsGroups.length + 1}`,
		confirmButtonText: "Create",
		allowEnterKey: true,
		inputValidator: (groupName) => {
			if (awsGroups.filter((g) => g.name === groupName).length > 0) return "Group name already exists"
			return null
		},
	})

	if (!result.isConfirmed) return
	const groupName = result.value
	sendUpdateEvent(MODULE_EVENTS.AWS_GROUPS_UPDATE, {
		awsGroups: [
			...awsGroups,
			{ name: groupName, replicas: 1, containers: [], minCpu: "256", maxCpu: "256", minMemory: "256", maxMemory: "256" },
		],
	})
}

/**
 *
 */
export async function updateAWSGroupName(groupName: string, awsGroups: AWSGroupScheme[]) {
	const result = await SwalStyled.fire({
		title: "Update AWS Group",
		text: "Enter the name of the AWS group",
		input: "text",
		inputValue: groupName,
		inputPlaceholder: "ex. group1",
		confirmButtonText: "Update",
		allowEnterKey: true,
		inputValidator: (newGroupName) => {
			if (newGroupName.length === 0) return "Group name cannot be empty"
			if (newGroupName === groupName) return "Group name cannot be the same"
			if (awsGroups.filter((g) => g.name === newGroupName).length > 0)
				return "Group name already exists"
			return null
		},
	})

	if (!result.isConfirmed) return
	const newGroupName = result.value

	const newAWSGroups = awsGroups.map((g) => {
		if (g.name === groupName) return { ...g, name: newGroupName }
		return g
	})
	sendUpdateEvent(MODULE_EVENTS.AWS_GROUPS_UPDATE, { awsGroups: newAWSGroups })
}

/**
 *
 */
export async function updateAWSGroupReplicas(
	groupName: string,
	groupReplicas: number,
	awsGroups: AWSGroupScheme[]
) {
	const result = await SwalStyled.fire({
		title: "Update AWS Group",
		text: `Enter the number of replicas for ${groupName} AWS group`,
		input: "text",
		inputValue: groupReplicas,
		inputPlaceholder: "ex. 1",
		confirmButtonText: "Update",
		allowEnterKey: true,
		inputValidator: (groupReplicas) => {
			if (groupReplicas.length === 0) return "Replicas cannot be empty"
			if (!Number.isInteger(Number(groupReplicas))) return "Replicas must be a whole number"
			if (Number(groupReplicas) < 0) return "Replicas must be >= 0"
			return null
		},
	})

	if (!result.isConfirmed) return
	const newGroupReplicas = parseInt(result.value)

	const newAWSGroups = awsGroups.map((g) => {
		if (g.name === groupName) return { ...g, replicas: newGroupReplicas }
		return g
	})
	sendUpdateEvent(MODULE_EVENTS.AWS_GROUPS_UPDATE, { awsGroups: newAWSGroups })
}

/**
 *
 */
export async function updateAWSGroupCpuOrMemory(
	groupName: string,
	cpuOrMemory: "minCpu" | "maxCpu" | "minMemory" | "maxMemory",
	value: string,
	awsGroups: AWSGroupScheme[]
) {
	const newAWSGroups = awsGroups.map((g) => {
		if (g.name === groupName) return { ...g, [cpuOrMemory]: value }
		return g
	})
	sendUpdateEvent(MODULE_EVENTS.AWS_GROUPS_UPDATE, { awsGroups: newAWSGroups })
}

/**
 *
 */
export async function editAWSGroupContainer(
	groupName: string,
	containerName: string,
	awsGroups: AWSGroupScheme[]
) {
	const allowedServiceNames = [...Object.values(SOCKET_PATH)]
	allowedServiceNames.splice(allowedServiceNames.indexOf(SOCKET_PATH.INTERFACE), 1)

	const result = await SwalStyled.fire({
		title: "Edit AWS Group Container",
		html: `Enter {service name}:{#replicas}<br>Allowed names: ${allowedServiceNames.join(", ")}`,
		input: "text",
		inputValue: `${containerName}:${
			awsGroups.find((g) => g.name === groupName)?.containers?.find((c) => c.name === containerName)
				?.replicas
		}`,
		inputPlaceholder: "ex. service1:1",
		confirmButtonText: "Update",
		allowEnterKey: true,
		denyButtonText: "Delete",
		showDenyButton: true,
		inputValidator: (input) => {
			try {
				const [name, replicas] = input.split(":")
				if (name.length === 0) return "Service name cannot be empty"
				if (replicas.length === 0) return "Replicas cannot be empty"

				if (!allowedServiceNames.includes(name as SOCKET_PATH)) return "Service name not found"
				if (
					name !== containerName &&
					awsGroups.find((g) => g.name === groupName)?.containers?.find((c) => c.name === name)
				)
					return "Service name already exists"

				if (!Number.isInteger(Number(replicas))) return "Replicas must be a whole number"
				if (Number(replicas) < 0) return "Replicas must be >= 0"
				return null
			} catch (e) {
				return "Error occured. Make sure you entered the correct format"
			}
		},
	})

	// Delete
	if (result.isDenied) {
		const result2 = await SwalStyled.fire({
			title: "Delete AWS Group Container",
			text: `Are you sure you want to delete "${containerName}" container from group "${groupName}"?`,
			confirmButtonText: "Cancel",
			showDenyButton: true,
			denyButtonText: "Delete",
			reverseButtons: true,
		})

		if (!result2.isDenied) return

		const newAWSGroups = awsGroups.map((g) => {
			if (g.name === groupName) {
				return {
					...g,
					containers: g.containers?.filter((c) => c.name !== containerName),
				}
			}
			return g
		})
		sendUpdateEvent(MODULE_EVENTS.AWS_GROUPS_UPDATE, { awsGroups: newAWSGroups })
	}

	// Update
	if (result.isConfirmed) {
		const [name, replicas] = result.value.split(":")
		const newReplicas = parseInt(replicas)

		const newAWSGroups = awsGroups.map((g) => {
			if (g.name === groupName) {
				return {
					...g,
					containers: g.containers?.map((c) => {
						if (c.name === containerName) return { name, replicas: newReplicas }
						return c
					}),
				}
			}
			return g
		})
		sendUpdateEvent(MODULE_EVENTS.AWS_GROUPS_UPDATE, { awsGroups: newAWSGroups })
	}
}

/**
 *
 */
export async function createAWSGroupContainer(groupName: string, awsGroups: AWSGroupScheme[]) {
	const allowedServiceNames = [...Object.values(SOCKET_PATH)]
	allowedServiceNames.splice(allowedServiceNames.indexOf(SOCKET_PATH.INTERFACE), 1)

	const result = await SwalStyled.fire({
		title: "Create AWS Group Container",
		html: `Enter {service name}:{#replicas}<br>Allowed names: ${allowedServiceNames.join(", ")}`,
		input: "text",
		inputPlaceholder: "ex. service1:1",
		confirmButtonText: "Create",
		allowEnterKey: true,
		inputValidator: (input) => {
			try {
				const [name, replicas] = input.split(":")
				if (name.length === 0) return "Service name cannot be empty"
				if (replicas.length === 0) return "Replicas cannot be empty"

				if (!allowedServiceNames.includes(name as SOCKET_PATH)) return "Service name not found"
				if (awsGroups.find((g) => g.name === groupName)?.containers?.find((c) => c.name === name))
					return "Service name already exists"

				if (!Number.isInteger(Number(replicas))) return "Replicas must be a whole number"
				if (Number(replicas) < 0) return "Replicas must be >= 0"
				return null
			} catch (e) {
				return "Error occured. Make sure you entered the correct format"
			}
		},
	})

	if (!result.isConfirmed) return

	const [name, replicas] = result.value.split(":")
	const newReplicas = parseInt(replicas)

	const newAWSGroups = awsGroups.map((g) => {
		if (g.name === groupName) {
			return {
				...g,
				containers: [...(g.containers || []), { name, replicas: newReplicas }],
			}
		}
		return g
	})
	sendUpdateEvent(MODULE_EVENTS.AWS_GROUPS_UPDATE, { awsGroups: newAWSGroups })
}

/**
 *
 */
export async function deleteAWSGroup(groupName: string, awsGroups: AWSGroupScheme[]) {
	const result = await SwalStyled.fire({
		title: "Delete AWS Group",
		text: `Are you sure you want to delete "${groupName}" AWS group?`,
		confirmButtonText: "Cancel",
		showDenyButton: true,
		denyButtonText: "Delete",
		reverseButtons: true,
	})

	if (!result.isDenied) return

	const newAWSGroups = awsGroups.filter((g) => g.name !== groupName)
	sendUpdateEvent(MODULE_EVENTS.AWS_GROUPS_UPDATE, { awsGroups: newAWSGroups })
}
