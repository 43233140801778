import { MODULE_EVENTS_EXCLUDE_SECURE } from "./"

/**
 *
 */
export enum GLOBAL_EVENTS {
	TEST_EVENT = "TEST_EVENT",

	SUBSCRIBE = "SUBSCRIBE", // Subscribe to events [microservices -> brain]
	TERMINATE = "TERMINATE", // Terminate process [brain -> microservices]

	SECURED_EVENT = "SECURED_EVENT", // Send any event with data being encoded with public key

	CLIENT_KEY_EXCHANGE = "CLIENT_KEY_EXCHANGE",
	SERVER_KEY_EXCHANGE = "SERVER_KEY_EXCHANGE",

	CLIENT_AUTH_REQUEST = "CLIENT_AUTH_REQUEST",
	SERVER_AUTH_SUCCESS = "SERVER_AUTH_SUCCESS",
	SERVER_AUTH_FAILURE = "SERVER_AUTH_FAILURE",
	SERVER_AUTH_TIMEOUT = "SERVER_AUTH_TIMEOUT",
}

/**
 *
 */
export type GLOBAL_EVENTS_PARAMS = {
	[GLOBAL_EVENTS.TEST_EVENT]: {
		message: string
	}

	[GLOBAL_EVENTS.SUBSCRIBE]: MODULE_EVENTS_EXCLUDE_SECURE[]
	[GLOBAL_EVENTS.TERMINATE]: void

	[GLOBAL_EVENTS.SECURED_EVENT]: {
		eventName: MODULE_EVENTS_EXCLUDE_SECURE
		iv: string
		key: string
		securedData: string
	}

	[GLOBAL_EVENTS.CLIENT_KEY_EXCHANGE]: {
		clientPublicKey: string
	}
	[GLOBAL_EVENTS.SERVER_KEY_EXCHANGE]: {
		serverPublicKey: string
	}

	[GLOBAL_EVENTS.CLIENT_AUTH_REQUEST]: {
		authKey: string
	}
	[GLOBAL_EVENTS.SERVER_AUTH_SUCCESS]: void
	[GLOBAL_EVENTS.SERVER_AUTH_FAILURE]: { msg?: string }
	[GLOBAL_EVENTS.SERVER_AUTH_TIMEOUT]: void
}

// Verification that all events defined in ENUM have their params defined in type above
// 	If it throws error, it means that you forgot to add event params to type above
type _ = {
	[Property in GLOBAL_EVENTS]: GLOBAL_EVENTS_PARAMS[Property]
}
