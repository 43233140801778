import React, { memo } from "react"

import { Button, Col, Container, Row, Stack } from "react-bootstrap"

import Header_C, {
	HeaderBlockNumSection_C,
	HeaderConnectedServicesSection_C,
	HeaderSection,
	HeaderStartStopSection_C,
} from "../../../components/Header"
import Content, { ContentSection, ContentSpacer } from "../../../components/Content"
import Sidebar, { SidebarRootNavLinks, SidebarSection } from "../../../components/Sidebar"

import { AppState, sendUpdateEvent } from "../../../App"
import AWSGroupsTable from "./AWSGroupsTable"
import { SidebarSettingsNavLinks } from ".."

import { AWSGroupScheme } from "../../../common/types"

import { createAWSGroup } from "./actions"
import { MODULE_EVENTS } from "../../../common/moduleEvents"
import Popup from "../../../helper/Popup"

/**
 * Bridge settings page
 *  Route '/settings/aws'
 */
type SettingsAWSProps = {
	awsGroups: AppState["awsGroups"]
}
export default memo(function SettingsAWS({ awsGroups }: SettingsAWSProps) {
	return (
		<>
			<Header_C>
				<HeaderSection>
					<HeaderStartStopSection_C />
				</HeaderSection>
				<HeaderSection justifyContent="center">
					<HeaderConnectedServicesSection_C />
				</HeaderSection>
				<HeaderSection justifyContent="end">
					<HeaderBlockNumSection_C />
				</HeaderSection>
			</Header_C>

			<Container fluid className="main">
				<Row>
					<Col className="sidebarContainer">
						<Sidebar>
							<SidebarRootNavLinks />
							<SidebarSettingsNavLinks />

							<SidebarSection renderP gap={1}>
								<SideButtons awsGroups={awsGroups} />
							</SidebarSection>
						</Sidebar>
					</Col>
					<Col className="contentContainer">
						<Content>
							<ContentSection>
								<AWSGroupsTable awsGroups={awsGroups} />
							</ContentSection>
							<ContentSection>
								<ContentSpacer height={100} />
							</ContentSection>
						</Content>
					</Col>
				</Row>
			</Container>
		</>
	)
})

/**
 *
 */
type SideButtonsProps = {
	awsGroups: AWSGroupScheme[]
}
const SideButtons = memo(function SideButtons({ awsGroups }: SideButtonsProps) {
	return (
		<Stack direction="vertical" gap={3}>
			<Button size="sm" variant="success" onClick={() => createAWSGroup(awsGroups)}>
				Create new group
			</Button>
			<Button
				size="sm"
				variant="warning"
				onClick={() => {
					Popup.confirm({
						title: `Restart AWS formation`,
						text: `Are you sure you want to restart AWS formation?`,
						onConfirm: () => {
							sendUpdateEvent(MODULE_EVENTS.RESTART_AWS_FORMATION, undefined)
						},
					})
				}}
			>
				Restart AWS formation
			</Button>
			<br></br>
			<Button
				size="sm"
				variant="info"
				onClick={() => {
					Popup.confirm({
						title: `SyncDB`,
						text: `🔴 🟡 🔴 PLEASE READ 🔴 🟡 🔴<br/><br/>SyncDB operation reloads all values from the database back into cache WITHOUT letting other microservices (including interface) about those changes.<br/><br/>To see changes on your end - <strong>reload the page after SyncDB</strong> success confirmation.`,
						onConfirm: () => {
							sendUpdateEvent(MODULE_EVENTS.SYNC_DB, undefined)
						},
					})
				}}
			>
				SyncDB
			</Button>
			<Button
				size="sm"
				variant="danger"
				onClick={() => {
					Popup.confirm({
						title: `Restart brain`,
						text: `Are you sure you want to restart brain?`,
						onConfirm: () => {
							sendUpdateEvent(MODULE_EVENTS.RESTART_SERVICE, { serviceName: "brain" })
						},
					})
				}}
			>
				Restart brain
			</Button>
		</Stack>
	)
})
