import secureLocalStorage from "react-secure-storage"
import { ENCRYPTION_WORKER_RESULT } from "./EncryptionWorker"

/**
 * Mapping of storage keys to the type of value they store
 */
type STORAGE_KEY_TYPE = {
	WALLET_PRIVATE_KEY: string
	RSA_KEY_PAIR: ENCRYPTION_WORKER_RESULT["GENERATE_KEYS"]
}

/**
 * Static class to read/write to/from local storage in a secured way.
 *  Secure storage library generate a secure key for every browser and encrypt the data using this key,
 *  which means only the browser which encrypted the data can decrypt it
 */
export default abstract class SecureLocalStorage {
	/**
	 * Function to set value to secure local storage
	 * @param key key to be added
	 * @param value value to be added
	 */
	static setItem<K extends keyof STORAGE_KEY_TYPE>(key: K, value: STORAGE_KEY_TYPE[K]) {
		secureLocalStorage.setItem(key, value)
	}

	/**
	 * Function to get value from secure local storage
	 * @param key key to get
	 */
	static getItem<K extends keyof STORAGE_KEY_TYPE>(key: K): STORAGE_KEY_TYPE[K] | null {
		return secureLocalStorage.getItem(key) as STORAGE_KEY_TYPE[K] | null
	}

	/**
	 * Function to remove item from secure local storage
	 * @param key key to be removed
	 */
	static removeItem<K extends keyof STORAGE_KEY_TYPE>(key: K) {
		secureLocalStorage.removeItem(key)
	}

	/**
	 * Function to clear secure local storage
	 */
	static clear() {
		secureLocalStorage.clear()
	}
}
