import * as Sounds from "../assets/sounds"

const volumes: { [key in keyof typeof Sounds]: number } = {
	dindilin: 1,
	beep: 1,
	error: 1,
	plop: 0.5,
}

export default class Sound {
	static play(sound: keyof typeof Sounds) {
		try {
			const audio = new Audio(Sounds[sound])
			audio.volume = volumes[sound]
			audio.play().catch(() => {})
		} catch (e) {}
	}
}
